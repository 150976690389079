<!--
 * @Author: 569353634@qq.com
 * @Date: 2023-02-18 08:18:04
 * @LastEditors: 569353634@qq.com
 * @LastEditTime: 2023-02-19 12:44:13
-->
<template>
  <div class="select-box">
    <div class="label">{{ labelName }}</div>
    <el-select @clear="clearHandle" class="select-wrap" v-model="valueSelect" :clearable="true" placeholder="请选择"
      @focus="focusHandler"  @change="visibleChange">
      <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"
        :disabled="is_pro_selection_concat">
      </el-option>
    </el-select>
  </div>
</template>
<script>
export default {
  props: {
    selectData: {
      type: Array,
      require: true,
      default: () => []
    },
    id: {
      type: Number,
      require: false
    },
    type: {
      type: String,
      require: true
    },
    labelName: {
      type: String,
      require: true
    },
    selection_pid: {
      type: Number
    },
    is_pro_selection_concat: {
      type: Boolean,
      require: false,
      default: false
    },
    goods_pro_params_id: {
      type: Number,
    },
    value: {
      type: [Number, String]
    }
  },

  watch: {
    value(val) {
      if (!val && !this.valueSelect) return;
      if (val === this.valueSelect) return;
      // if (val !== '') {
      //   this.selectHandle(val)
      // }
      this.valueSelect=val;
      this.visibleChange()
      //this.valueSelect=val
    }
  },
  computed: {
    
    options() {
      let selectData = JSON.parse(JSON.stringify(this.selectData))
      if (this.type === 'params_selection') {
        let newOptions = []
        selectData.forEach(item => {
          newOptions.push({ value: item.id, label: item.name })
        })
        return newOptions
      } else if (this.type === 'pro_code_id') {
        let newOptions = []
        selectData.forEach(item => {
          newOptions.push({ value: item.id, label: item.pro_code })
        })
        return newOptions
      } else if (this.type === 'pro_selection') {
        let newOptions = []
        selectData.forEach(item => {
          newOptions.push({ value: item.select_value, label: item.select_value })
        })
        return newOptions
      }
      return []
    }
  },
  created(){
    this.valueSelect=this.value;
    //console.log("selectData===========",this.selectData)
  },
  data() {
    return {
      valueSelect:'',
      
    }
  },
  methods: {
    init() {},
    focusHandler(){
      if (this.is_pro_selection_concat) {
        this.$message.error("请先选择产品代码")
      }
    },
    visibleChange() {
        this.selectHandle(this.valueSelect)
    },
    selectHandle(value) {
      //debugger
      if (value === '') return false
      if (this.type === 'params_selection') {
        this.$emit('selectHandle', 'params_selection', { selection_pid: this.selection_pid, selection_id: value })
      } else if (this.type === 'pro_code_id') {
        this.$emit('selectHandle', 'pro_code_id', value)
      } else if (this.type === 'pro_selection') {
        this.$emit('selectHandle', 'pro_selection', {
          type: 'select',
          value: value,
          name: this.labelName,
          id: this.id,
          goods_pro_params_id: this.goods_pro_params_id,
          var_type: 1
        })
      }
    },
    clearHandle() {
      if (this.type === 'params_selection') {
        this.$emit('clearHandle', 'params_selection', { selection_pid: this.selection_pid, selection_id: this.value1 })
      } else if (this.type === 'pro_code_id') {

        this.$emit('clearHandle', 'pro_code_id', this.value1)
      } else if (this.type === 'pro_selection') {
        this.$emit('clearHandle', 'pro_selection', {
          type: 'select',
          value: this.value1,
          name: this.labelName,
          id: this.id,
          goods_pro_params_id: this.goods_pro_params_id,
          var_type: 1
        })
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.select-box {
  display: flex;
  align-items: center;
  margin-right: 10px;
  margin-top: 10px;

  &:nth-child(-n + 3) {
    margin-top: 0;
  }

  &:nth-child(3n) {
    margin-right: 0;
  }

  width: 230px;

  .label {
    color: #333;
    white-space: nowrap;
    border: 1px solid #DCDFE6;
    border-right: 0 none;
    line-height: 28px;
    padding: 0 8px;
    // font-size: 13px;
  }

  .select-wrap {
    flex: 1;
  }
}
</style>