<template>
  <div class="select-box">
    <div class="label">{{ labelName }}</div>
    <div class="select-wrap" @click="clickHandle">
      <input :class="cnopmuedIllage" @blur="blurHandle" v-model="value" placeholder="请输入"
        :disabled="is_pro_selection_concat" type="text">
    </div>
    <div class="range">
      <span>{{ rangeData.min }}-{{ rangeData.max }}/{{ rangeData.step }}</span>
      <el-tooltip class="item" effect="dark" :content="'步进值' + rangeData.step" placement="right">
        <span class="icon iconfont icon-wenhao-yuankuang"></span>
      </el-tooltip>
    </div>
  </div>
</template>
<script>
// import { isNumber } from '../../utils/index'
export default {
  props: {
    type: {
      type: String,
      require: true
    },
    id: {
      type: Number,
      require: false
    },
    labelName: {
      type: String,
      require: true
    },
    checkerror: {
      type: Boolean,
      require: false,
      default: false
    },
    rangeData: {
      type: Object,
      require: true
    },
    goods_pro_params_id: {
      type: Number
    },
    is_pro_selection_concat: {
      type: Boolean,
      require: false,
      default: false
    },
    rangeValue: {
      type: [Number, String]
    },
    addtion: {
      type: Array,
      require: false,
      default: () => []
    }
  },
  watch: {
    rangeValue(val) {
      console.log(val, 666666)
      if (val) {
        this.value = val;
      }
    }
  },
  computed: {
    cnopmuedIllage() {
      if (this.isLegal && !this.checkerror) {
        return ""
      } else {
        return 'illegal'
      }

    }

  },
  data() {
    return {
      value: '',
      isLegal: true
    }
  },
  methods: {
    clickHandle() {
      if (this.is_pro_selection_concat) {
        this.$message.error("请先选择产品代码")
      }
    },
    init() {
      this.value = ''
      this.isLegal = true
    },
    blurHandle() {

      console.log((Number(this.value) * 10000 - this.rangeData.min * 10000) % (this.rangeData.step * 10000), (Number(this.value) * 10000 - this.rangeData.min * 10000), (this.rangeData.step * 10000))
      if (this.value === '') {
        this.isLegal = true
        this.$emit('rangeHandle', 'pro_selection', {
          type: 'number',
          value: this.value,
          name: this.labelName,
          goods_pro_params_id: this.goods_pro_params_id,
          id: this.id
        })
      } if (this.addtion.some(i => i == this.value)) {
        this.isLegal = true
        this.$emit('rangeHandle', 'pro_selection', {
          type: 'number',
          value: this.value,
          name: this.labelName,
          goods_pro_params_id: this.goods_pro_params_id,
          id: this.id,
          var_type: 1
        })
      } else {
        if (!Number(this.value) == this.value) {
          this.isLegal = false
          this.$message({
            message: '错误的步进值',
            type: 'error',
            duration: 2000
          })
          return false
        } else if (Number(this.value) < this.rangeData.min || Number(this.value) > this.rangeData.max) {
          this.isLegal = false
          this.$message({
            message: '请输入范围内的值',
            type: 'error',
            duration: 2000
          })
          return false
        } else if (!((Number(this.value) * 10000) % (this.rangeData.step * 10000) === 0)) {
          this.isLegal = false
          this.$message({
            message: '错误的步进值',
            type: 'error',
            duration: 2000
          })
          return false
        } else {
          this.isLegal = true
          if (this.type === 'pro_selection') {
            this.$emit('rangeHandle', 'pro_selection', {
              type: 'number',
              value: this.value,
              name: this.labelName,
              goods_pro_params_id: this.goods_pro_params_id,
              id: this.id,
              var_type: 1
            })
          }
        }
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.select-box {
  display: flex;
  align-items: center;
  margin-right: 10px;
  margin-top: 10px;
  overflow: hidden;
  justify-content: space-between;

  &:nth-child(-n + 3) {
    margin-top: 0;
  }

  &:nth-child(3n) {
    margin-right: 0;
  }

  width: 230px;

  .label {
    color: #333;
    white-space: nowrap;
    border: 1px solid #DCDFE6;
    border-right: 0 none;
    line-height: 28px;
    padding: 0 10px;
    // font-size: 13px;
  }

  .select-wrap {
    height: 100%;
    flex: 1;

    input {
      width: 100%;
      border: 1px solid #DCDFE6;
      outline: none;
      box-sizing: border-box;
      height: 100%;
      padding: 0 8px;
      color: #3385FF;

      &.illegal {
        border: 1px solid #ff7070;
        color: #ff7070;

        &:focus {
          border-color: #ff7070;
        }
      }

      &:focus {
        border-color: #3385FF;
      }

      &::-webkit-input-placeholder {
        color: rgba(51, 133, 255, .5);
      }

      &:-moz-placeholder {
        /* FF 4-18 */
        color: rgba(51, 133, 255, .5);
      }

      &::-moz-placeholder {
        /* FF 19+ */
        color: rgba(51, 133, 255, .5);
      }

      &:-ms-input-placeholder {
        /* IE 10+ */
        color: rgba(51, 133, 255, .5);
      }
    }
  }

  .range {
    // width: 30%;
    // flex: 1;
    display: flex;
    align-items: center;
    white-space: nowrap;
    border: 1px solid #DCDFE6;
    border-left: 0 none;
    height: 100%;
    padding: 0 8px;

    .iconfont {
      margin-left: 3px;
    }
  }
}
</style>