<template>
  <div class="range-box">
    <div class="name" v-if="listData.length > 1">{{ name }}:</div>
    <div class="list">
      <div v-for="(item, index) in listData" :key="index" class="item">
        <div class="label">{{ item.value_name }}</div>
        <input @blur="blurHandle(item)" v-model="item.input_value" :class="[item.isLegal ? '' : 'illegal', 'input']"
          placeholder="请输入" />
        <div class="range">
          <span>{{ item.min }}-{{ item.max }}/{{ item.step }}</span>
          <el-tooltip effect="dark" :content="'步进值' + item.step" placement="right">
            <span class="icon iconfont icon-wenhao-yuankuang"></span>
          </el-tooltip>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { isNumber } from '../../utils/index'
export default {
  props: {
    name: {
      type: String
    },
    rangeData: {
      type: Array
    },
    id: {
      type: Number
    },

  },
  data() {
    return {}
  },
  created() {
    let newRangeData = JSON.parse(JSON.stringify(this.rangeData))
    newRangeData.forEach(item => {
      item.input_value = ''
      item.isLegal = true
    })
    this.listData = newRangeData
  },
  methods: {
    init() {
      this.listData.forEach(item => {
        item.input_value = ''
      })
    },
    blurHandle() {
      const _this = this
      this.listData.forEach(item => {
        // console.log(item, Number(item.input_value) == this.value)
        if (item.input_value !== '') {
          if (!(Number(item.input_value) == item.input_value) || !(((Number(item.input_value) * 10000 - item.min * 10000) % (item.step * 10000) === 0))) {
            item.isLegal = false
            _this.$message({
              message: '错误的步进值',
              type: 'error',
              duration: 2000
            })
          } else if (Number(item.input_value) > item.max || Number(item.input_value) < item.min) {
            item.isLegal = false
            _this.$message({
              message: '请输入范围内的值',
              type: 'error',
              duration: 2000
            })
          } else {
            item.isLegal = true
          }
        } else {
          this.isLegal = true
        }
      })
      this.$forceUpdate()
      // const error_1 = this.listData.some(item => {
      //   if (item.input_value !== '' && (!isNumber(item.input_value) || item.input_value % item.step !== 0)) {
      //     item.isLegal = false
      //     this.$message({
      //       message: '错误的步进值',
      //       type: 'error',
      //       duration: 2000
      //     })
      //   } else if (item.input_value !== '' && (Number(item.input_value) > item.max || Number(item.input_value) < item.min)) {
      //     item.isLegal = false
      //   }
      //   return item.input_value !== '' && (!isNumber(item.input_value) || item.input_value % item.step !== 0)
      // })
      // const error_2 = this.listData.some(item => {
      //   if (item.input_value !== '' && (Number(item.input_value) > item.max || Number(item.input_value) < item.min)) {
      //     item.isLegal = false
      //   } else {
      //     item.isLegal = true
      //   }
      //   return item.input_value !== '' && (Number(item.input_value) > item.max || Number(item.input_value) < item.min)
      // })
      console.log(this.listData, 33333)
      const legalData = this.listData.filter(item => {
        console.log(444, isNumber(item.input_value))
        console.log(555, item.input_value % item.step)
        return item.input_value !== '' && (isNumber(item.input_value) && (Number(item.input_value) * 100) % (Number(item.step) * 100) === 0 && Number(item.input_value) >= item.min && Number(item.input_value) <= item.max)
      })
      console.log(2222, legalData)
      this.$emit('rangeHandle', 'optional_processing', {
        id: this.id,
        opt_name: this.name,
        type: 'number',
        optional_value: legalData
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.range-box {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .name {
    font-weight: bold;
  }

  .list {
    overflow: hidden;
    flex: 1;

    .item {
      border: 1px solid #DCDFE6;
      display: flex;
      float: left;
      align-items: center;
      margin-left: 10px;
      margin-top: 3px;

      .label {
        padding: 0 8px;
        font-size: 14px;
        color: #606266;
      }

      input {
        width: 70px;
        height: 22px;
        border: 0 none;
        outline: none;
        color: #606266;
        font-size: 14px;
        padding-right: 8px;
        color: #3385FF;

        &.illegal {
          color: #ff7070;
        }

        &::-webkit-input-placeholder {
          color: rgba(51, 133, 255, .5);
          font-size: 13px;
        }

        &:-moz-placeholder {
          /* FF 4-18 */
          color: rgba(51, 133, 255, .5);
          font-size: 13px;
        }

        &::-moz-placeholder {
          /* FF 19+ */
          color: rgba(51, 133, 255, .5);
          font-size: 13px;
        }

        &:-ms-input-placeholder {
          /* IE 10+ */
          color: rgba(51, 133, 255, .5);
          font-size: 13px;
        }
      }

      .range {
        display: flex;
        align-items: center;
        white-space: nowrap;
        font-size: 14px;
        padding: 0 8px;
        color: #999;
        border-left: 1px solid #DCDFE6;

        // font-size: 14px;
        .iconfont {
          font-size: 14px;
          margin-left: 3px;
        }
      }
    }
  }

}
</style>