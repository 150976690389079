<template>
  <div class="checkbox-container">
    <div class="name">{{ name }}：</div>
    <span @click="selectHandle(false)" v-if="checked" class="icon iconfont icon-checkbox-xuanzhong"></span>
    <span @click="selectHandle(true)" v-else class="icon iconfont icon-checkbox-weixuan"></span>
  </div>
</template>
<script>
export default {
  props: {
    name: {
      type: String
    },
    type: {
      type: String
    },
    id: {
      type: Number
    },
    checked: {
      type: [Boolean]
    }
  },
  watch: {
    checked(val) {
      this.isChecked = val
    }
  },
  data() {
    return {
      isChecked: false
    }
  },
  methods: {
    init() {
      this.isChecked = false
    },
    selectHandle(value) {
      this.isChecked = value
      this.$emit('selectHandle', 'optional_processing', {
        id: this.id,
        opt_name: this.name,
        type: 'checkbox',
        input_value: value,
        isChecked: this.isChecked
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.checkbox-container {
  display: flex;
  align-items: center;
  height: 27px;

  .name {
    font-weight: bold;
  }

  .iconfont {
    font-size: 20px;
    cursor: pointer;
    color: rgba(51, 133, 255, .5);

    &.icon-checkbox-xuanzhong {
      color: #3385FF;
    }
  }
}</style>