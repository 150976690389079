<!--
 * @Author: 569353634@qq.com
 * @Date: 2023-02-18 08:18:04
 * @LastEditors: 569353634@qq.com
 * @LastEditTime: 2023-02-18 08:50:08
-->
<template>
	<div id="goods-detail-container">
		<MainHeader/>
		<CommonTop/>
		<div class="section breadcrumb-container">
			<div class="breadcrumb page-content-wrap">
				<span>所在位置：</span>
				<div class="breadcrumb-item">
					<router-link class="nav-item" to="/">首页</router-link>
				</div>
				<div v-if="navData.parent" class="breadcrumb-item">
					<span class="icon iconfont icon-hangdongjiantou"></span>
					<router-link class="nav-item" :to="`/category/list?id=${navData.parent.id}`">{{
							navData.parent.name
						}}
					</router-link>
				</div>
				<div v-if="navData.id" class="breadcrumb-item">
					<span class="icon iconfont icon-hangdongjiantou"></span>
					<router-link class="nav-item" :to="`/goods/list?id=${navData.id}`">{{ navData.name }}</router-link>
				</div>
				<div class="breadcrumb-item">
					<span class="icon iconfont icon-hangdongjiantou"></span>
					<span class="nav-item">{{ goodsDetail.name }}</span>
				</div>
			</div>
		</div>
		<div class="section">
			<!-- fuyong -->
			<div class="goods-detail-container page-content-wrap">
				<div class="goods-covers">
					<div class="main-pic">
						<pic-zoom
								v-if="goodsDetail.pic_group && goodsDetail.pic_group[picIndex]"
								:url="goodsDetail.pic_group[picIndex]" :scale="2"></pic-zoom>
						<!-- <img v-if="goodsDetail.pic_group" :src="goodsDetail.pic_group[picIndex]" alt=""> -->
					</div>
					<div class="pic-group">
						<div @click="slideHandle('prev')" class="prev"><span class="icon iconfont icon-hangdongjiantou"></span>
						</div>
						<swiper class="pic-swiper" ref="picSwiper" :options="picSwiperOptions">
							<swiper-slide
									:class="[picIndex === index ? 'active' : '', 'pic-slide-item']"
									v-for="(item, index) in goodsDetail.pic_group" :key="index">
								<img :src="item" alt="">
							</swiper-slide>
						</swiper>
						<div @click="slideHandle('next')" class="next"><span class="icon iconfont icon-hangdongjiantou"></span>
						</div>
					</div>
					<!-- <div class="user-actions">                                                                                                                                                                                                                                                                                                                                           </div> -->
				</div>
				<div class="goods-detail">
					<div class="goods-name">{{ goodsDetail.name }}</div>
					<div class="tools">
						<div class="btn" @click="openModelClick" style="background: #D9262C !important;">3d模型下载</div>
						<div @click="jumpDownLoad" class="btn" style="background-color: #f5f5f5 !important;">PDF下载</div>
						<div @click="paramsReset" class="btn" style="background-color: #fdd000; color: black;margin-left: 10px;">
							参数重置
						</div>
					</div>
					<div class="filter-container">
						<div class="filter-item condition">
							<div class="label">条件筛选：</div>
							<div v-if="goodsDetail && goodsDetail.condition" class="data-box">
								<div v-for="(item, index) in goodsDetail.condition" :key="index" class="data-item">
									<span>{{ item.parent.name }} </span>
									<span class="light-color">{{ item.name }}</span>
									<!-- <span class="light-color" style="color: #D9262C">{{ item.name }}</span> -->

									<!--  -->
								</div>
							</div>
						</div>
						<div class="filter-item parameter">
							<div class="label">参数选型：</div>
							<div v-if="selectionData.params_selection" class="data-box">
								<SelectBox
										:value="params_selection_values['params_selection_' + item.parent_selection.id]"
										ref="params_selection" @clearHandle="clearHandle" @selectHandle='selectHandle'
										:selection_pid="item.parent_selection.id" :type="'params_selection'"
										:labelName="item.parent_selection.name" :selectData="item.child_selection"
										v-for="(item, index) in selectionData.params_selection" :key="index"
										style="color: #f1d6d6;"/>
							</div>
						</div>
						<div class="filter-item parameter">
							<div class="label">产品选型：</div>
							<div class="data-box">
								<SelectBox
										:value="pro_code_value" ref="pro_code_id" @clearHandle="clearHandle"
										@selectHandle='selectHandle' v-if="selectionData.pro_code" :type="'pro_code_id'"
										:labelName="'代码'"
										:selectData="selectionData.pro_code"></SelectBox>
								<template v-if="selectionData.pro_selection">
									<template v-if="params.pro_code_id && pro_selection_show">
										<template v-for="(item, index) in selectionData.pro_selection">
											<SelectBox
													:value="pro_selection_values['pro_selection_' + item.id]" ref="pro_selection"
													@clearHandle="clearHandle" @selectHandle='selectHandle'
													v-if="item.param_type === 1 && item.var_type === 1 && item.specific_param.length"
													:key="index"
													:goods_pro_params_id="item.goods_pro_params_id" :type="'pro_selection'" :id="item.id"
													:labelName="item.product_param" :selectData="item.specific_param"></SelectBox>
											<RangeBox
													:rangeValue="pro_selection_values['pro_selection_' + item.id]" ref="pro_selection"
													@rangeHandle="rangeHandle" v-else-if="item.param_type == 2 && item.var_type === 1"
													:key="index"
													:labelName="item.product_param" :addtion="item.specific_param.addtion"
													:checkerror="item.specific_param.inrange"
													:goods_pro_params_id="item.goods_pro_params_id"
													:type="'pro_selection'" :id="item.id" :rangeData="item.specific_param">
											</RangeBox>
										</template>
									</template>
									<template v-else>
										<template v-for="(item, index) in selectionData.pro_selection_concat">
											<SelectBox
													:value="pro_selection_values['pro_selection_' + item.id]" ref="pro_selection"
													@clearHandle="clearHandle" @selectHandle='selectHandle'
													:is_pro_selection_concat="item.isSelectionConcat"
													v-if="item.param_type === 1 && item.var_type === 1 && item.specific_param.length"
													:key="index"
													:goods_pro_params_id="item.goods_pro_params_id" :type="'pro_selection'" :id="item.id"
													:labelName="item.product_param" :selectData="item.specific_param"></SelectBox>
											<RangeBox
													:rangeValue="pro_selection_values['pro_selection_' + item.id]" ref="pro_selection"
													@rangeHandle="rangeHandle" v-else-if="item.param_type == 2 && item.var_type === 1"
													:key="index"
													:labelName="item.product_param" :addtion="item.specific_param.addtion"
													:is_pro_selection_concat="item.isSelectionConcat"
													:checkerror="item.specific_param.inrange"
													:goods_pro_params_id="item.goods_pro_params_id" :type="'pro_selection'" :id="item.id"
													:rangeData="item.specific_param">
											</RangeBox>
										</template>
									</template>
								</template>
							</div>
						</div>
						<div
								v-show="selectionData.production_model && selectionData.optional_processing.length > 0"
								:class="[isShowOptional ? 'show' : '', 'filter-item', 'optional-processing']">
							<div @click="isShowOptional = !isShowOptional" class="label">追加工</div>
							<div class="data-box" v-if="pro_selection_show && selectionData.production_model">
								<div class="list">
									<div v-for="(item, index) in selectionData.optional_processing" :key="item.index" class="list-item">
										<OptionalRadio
												:checked="item.optional_value" ref="optional_processing" @selectHandle='selectHandle'
												:id="item.index" :type="'optional_processing'" :name="item.opt_name"
												v-if="item.type === 'checkbox'">
										</OptionalRadio>
										<OptionalRange
												ref="optional_processing" @rangeHandle="rangeHandle" :id="item.index"
												:type="'optional_processing'" :name="item.opt_name" :rangeData="item.optional_value"
												v-else-if="item.type === 'number'">
										</OptionalRange>
									</div>
								</div>
							</div>
						</div>
						<div class="filter-item model">
							<div class="label">生成型号：</div>
							<div class="data-box">
                <span
		                class="light-color">{{ productionModelComputed || '---' }}<button
		                v-show="selectionData.production_model" class="copy-btn" type="button" @click="doCopy"
		                style="background-color: #fdd000 ;color: #000;">复制</button></span>

							</div>
						</div>
					</div>

					<div v-if="selectionErrorText.length > 0" class="selectionErrorText"><i class="el-icon-warning"></i>{{
							selectionErrorText
						}}
					</div>
					<div class="num-box">
						<div class="label">购买数量：</div>
						<div class="data-box">
							<el-input-number
									size="medium" @change="numberChange" v-model="num" :min="1" :max="100000"
									label="描述文字"></el-input-number>
						</div>
					</div>
					<div class="price-box">
						<div
								v-if="ask_price_type != 'quotation'" @click="askPrice"
								:class="[selectionData.production_model ? 'allowed' : 'no-allow', 'baojia']">询价
						</div>
						<div
								v-else @click="quotekPrice"
								:class="[selectionData.production_model ? 'allowed' : 'no-allow', 'baojia']">
							报价
						</div>
						<div class="price-detail">
							<div class="price-item">
								<div class="label">折扣单价：</div>
								<div style="color: #D9262C" class="data"><span v-if="priceData.price">¥ </span>{{ discountPricr }}</div>
							</div>
							<div class="price-item">
								<div class="label">折扣单价(含税)：</div>
								<div style="color: #D9262C" class="data"><span v-if="priceData.tax_price">¥ </span>{{
										discountTaxPricr
									}}
								</div>
							</div>
						</div>
						<div class="price-detail">
							<div class="price-item">
								<div class="label">合计：</div>
								<div style="color: #D9262C" class="data"><span
										v-if="priceData.price">¥ </span>{{ priceData.price ? discountPrice : "" }}
								</div>
							</div>
							<div class="price-item">
								<div class="label">发货天数：</div>
								<div class="data">{{ priceData.ship_days }}</div>
							</div>
						</div>
					</div>
					<div class="actions">
						<a
								class="btn zx" target="_blank" href="http://wpa.qq.com/msgrd?v=3&uin=800090801&site=qq&menu=yes"
								style="border-color: #D9262C;color: #D9262C">立即咨询</a>
						<div
								:class="[userInfo.company_admin_id === 0 ? 'allowed' : 'no-allow', 'btn buy']" @click="buyNow"
								style="background-color: #D9262C;color: #fff">立即购买
						</div>
						<div
								:class="[userInfo.company_admin_id === 0 ? 'allowed' : 'no-allow', 'btn', 'add']"
								style="background-color: #fdd000; color: black;" @click="addShopingCard">
							加入购物车
						</div>
						<div
								@click="collectHandle" style="border-color: #D9262C;color: #D9262C"
								:class="[userInfo.company_admin_id === 0 ? 'allowed' : 'no-allow', 'btn', 'btn-collect', selectionData && selectionData.is_collect == 1 ? 'collected' : '']">
							<span class="icon iconfont icon-shoucang"></span>
							<span>{{ selectionData.is_collect === 1 ? '已收藏' : '收藏' }}</span>
						</div>
					</div>
				</div>
			</div>
			<!-- jieshu -->


		</div>
		<div class="section related">
			<!-- page-content-wrap -->
			<div :class="['related-container', pageActive ? 'active' : '']">
				<div class="left" :key="leftPageKey">
					<!-- <div class="header">产品参数</div> -->
					<div v-if="goodsOptions[relateIndex] || relateIndex == -1" class="relate-content">
						<div class="relate-header-box">
							<div class="relate-cat">
								<div
										@click="relateHandle(index)" v-for="(item, index) in goodsOptions" :key="index"
										:class="[relateIndex === index ? 'active' : '', 'relate-cat-item']"> {{ item.name }}
								</div>
								<div @click="relateHandle(-1)" :class="[relateIndex === -1 ? 'active' : '', 'relate-cat-item']"> 3D模型预览
								</div>
							</div>
							<div
									v-if="relateIndex != -1 && goodsOptions[relateIndex].data && goodsOptions[relateIndex].data.length > 1"
									class="relate-tab">
								<div
										@click="relateTabHandle(index)" v-for="(item, index) in goodsOptions[relateIndex].data"
										:key="index"
										:class="[relateTabIndex === index ? 'active' : '', 'relate-tab-item']">{{ item.tabs_name }}
								</div>
							</div>
						</div>
						<div v-if="relateIndex != -1 && goodsOptions[relateIndex].name != '技术图档'" class="rich-text-container">
							<div class="rich-text" v-html="goodsOptions[relateIndex].data[relateTabIndex].content"></div>
						</div>
						<div
								v-else-if="relateIndex != -1 && goodsOptions[relateIndex].name == '技术图档'"
								class="rich-text-container">
							<div style="display: flex; align-items: center; height: 97vh;">
								<div>
									<div
											:class="[optIndex === 0 ? 'optactive' : '']" @click="optHandle(0)"
											style="width: 30px; border: 1px solid #5186ff; padding: 20px 8px; cursor: pointer; border-right: 0 none;border:1px solid  #D9262C;">
										{{ goodsOptions[relateIndex].data[0].name.split('-')[1] }}
									</div>
									<div
											:class="[optIndex === -1 ? 'optactive' : '']" @click="optHandle(-1)"
											style="width: 30px; border: 1px solid #5186ff; padding: 20px 8px; cursor: pointer;border:1px solid  #D9262C; border-right: 0 none; border-top: 0 none; border-bottom: 0 none;">
										合并
									</div>
									<div
											v-if="goodsOptions[relateIndex].data[1]" :class="[optIndex === 1 ? 'optactive' : '']"
											@click="optHandle(1)"
											style="width: 30px; border: 1px solid #5186ff; padding: 20px 8px; cursor: pointer; border-right: 0 none;border:1px solid #D9262C">
										{{ goodsOptions[relateIndex].data[1].name.split('-')[1] }}
									</div>
								</div>
								<div class="opt-container">
									<div v-if="optIndex != -1" class="opt-section">
										<div class="opt-tab-box">
											<div
													@click="obtTabHandle(index)"
													v-for="(item, index) in goodsOptions[relateIndex].data[optIndex].data" :key="index"
													:class="[optTabIndex === index ? 'active' : '', 'opt-tab-item']">{{ item.tabs_name }}
											</div>
										</div>
										<div class="scroll-y">
											<div
													class="rich-text"
													v-html="goodsOptions[relateIndex].data[optIndex].data[optTabIndex].content">
											</div>
										</div>
									</div>
									<div v-else style="display: flex; flex-direction: column; height: 100%;">
										<div class="opt-section">
											<div class="opt-tab-box">
												<div
														@click="obtTabHandle1(index)"
														v-for="(item, index) in goodsOptions[relateIndex].data[0].data"
														:key="index" :class="[optTabIndex1 === index ? 'active' : '', 'opt-tab-item']">
													{{ item.tabs_name }}
												</div>
											</div>
											<div class="scroll-y">
												<div
														class="rich-text"
														v-html="changeImgWidth(goodsOptions[relateIndex].data[0].data[optTabIndex1].content)">
												</div>
											</div>
										</div>
										<div
												style="width: 100%; height: 6px; background-color: #f1d6d6; border-radius: 3px; margin: 10px 0 10px 0;">
										</div>
										<div class="opt-section">
											<div class="opt-tab-box">
												<div
														@click="obtTabHandle2(index)"
														v-for="(item, index) in goodsOptions[relateIndex].data[1].data"
														:key="index" :class="[optTabIndex2 === index ? 'active' : '', 'opt-tab-item']">
													{{ item.tabs_name }}
												</div>
											</div>
											<div class="scroll-y">
												<div
														class="rich-text"
														v-html="changeImgWidth(goodsOptions[relateIndex].data[1].data[optTabIndex2].content)">
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div v-else>
							<iframe
									style="width: 100%; height: 97vh;" v-if="selectionData.production_model" :src="iframeSrc"
									frameborder="0"></iframe>
							<div
									v-else
									style="width: 100%; height: 97vh; display: flex; justify-content: center; align-items: center; flex-direction: column;">
								<div style="font-size: 18px; font-weight: bold; margin-bottom: 15px;">抱歉，未生成产品型号</div>
								<div style="width: 180px; margin-bottom: 15px; color: #999999;">建议您:</div>
								<div style="width: 180px;">添加筛选条件,生成产品型号</div>
							</div>
						</div>
					</div>
				</div>

				<!-- kaishi -->
				<div class="right-section">
					<div class="goods-img">
						<img
								v-if="goodsDetail.pic_group && goodsDetail.pic_group[picIndex]" :src="goodsDetail.pic_group[picIndex]"
								alt="">
					</div>
					<div class="goods-detail">
						<div class="goods-name">{{ goodsDetail.name }}</div>
						<div class="tools">
							<div class="btn" @click="openModelClick" style="background: #D9262C !important;">3d模型下载</div>
							<div @click="jumpDownLoad" class="btn" style="background-color: #f5f5f5 !important;">PDF下载</div>
							<div @click="paramsReset" class="btn" style="background-color: #fdd000; color: black;">参数重置</div>
						</div>
						<div class="filter-container">
							<div class="filter-item condition">
								<div class="label">条件筛选：</div>
								<div v-if="goodsDetail && goodsDetail.condition" class="data-box">
									<div v-for="(item, index) in goodsDetail.condition" :key="index" class="data-item">
										<span>{{ item.parent.name }} </span>
										<span class="light-color">{{ item.name }}</span>
									</div>
								</div>
							</div>
							<div class="filter-item parameter">
								<div class="label">参数选型：</div>
								<div v-if="selectionData.params_selection" class="data-box">
									<SelectBox
											:value="params_selection_values['params_selection_' + item.parent_selection.id]"
											ref="params_selection1" @clearHandle="clearHandle" @selectHandle='selectHandle'
											:selection_pid="item.parent_selection.id" :type="'params_selection'"
											:labelName="item.parent_selection.name" :selectData="item.child_selection"
											v-for="(item, index) in selectionData.params_selection" :key="index"></SelectBox>
								</div>
							</div>
							<div class="filter-item parameter">
								<div class="label">产品选型：</div>
								<div class="data-box">
									<SelectBox
											:value="pro_code_value" ref="pro_code_id1" @clearHandle="clearHandle"
											@selectHandle='selectHandle' v-if="selectionData.pro_code" :type="'pro_code_id'"
											:labelName="'代码'"
											:selectData="selectionData.pro_code"></SelectBox>
									<template v-if="selectionData.pro_selection">
										<template v-if="params.pro_code_id">
											<template v-for="(item, index) in selectionData.pro_selection">
												<SelectBox
														:value="pro_selection_values['pro_selection_' + item.id]" ref="pro_selection1"
														@clearHandle="clearHandle" @selectHandle='selectHandle'
														v-if="item.param_type === 1 && item.var_type === 1" :key="index"
														:goods_pro_params_id="item.goods_param_id" :type="'pro_selection'" :id="item.id"
														:labelName="item.product_param" :selectData="item.specific_param">
												</SelectBox>
												<RangeBox
														:rangeValue="pro_selection_values['pro_selection_' + item.id]" ref="pro_selection1"
														@rangeHandle="rangeHandle" v-else-if="item.param_type === 2 && item.var_type === 1"
														:key="index"
														:labelName="item.product_param" :goods_pro_params_id="item.goods_pro_params_id"
														:id="item.id"
														:type="'pro_selection'" :rangeData="item.specific_param"></RangeBox>
											</template>
										</template>
										<template v-else>
											<template v-for="(item, index) in selectionData.pro_selection_concat">
												<SelectBox
														:value="pro_selection_values['pro_selection_' + item.id]" ref="pro_selection1"
														@clearHandle="clearHandle" @selectHandle='selectHandle'
														:is_pro_selection_concat="item.isSelectionConcat"
														v-if="item.param_type === 1 && item.var_type === 1" :key="index"
														:goods_pro_params_id="item.goods_param_id" :type="'pro_selection'" :id="item.id"
														:labelName="item.product_param" :selectData="item.specific_param">
												</SelectBox>
												<RangeBox
														:rangeValue="pro_selection_values['pro_selection_' + item.id]" ref="pro_selection1"
														:is_pro_selection_concat="item.isSelectionConcat" @rangeHandle="rangeHandle"
														v-else-if="item.param_type === 2 && item.var_type === 1" :key="index"
														:labelName="item.product_param" :goods_pro_params_id="item.goods_pro_params_id"
														:id="item.id"
														:type="'pro_selection'" :rangeData="item.specific_param"></RangeBox>
											</template>
										</template>

									</template>
								</div>
							</div>
							<div
									v-show="selectionData.production_model && selectionData.optional_processing.length > 0"
									:class="[isShowOptional ? 'show' : '', 'filter-item', 'optional-processing']">
								<div @click="isShowOptional = !isShowOptional" class="label">追加工</div>
								<div class="data-box">
									<div class="list">
										<div v-for="(item, index) in selectionData.optional_processing" :key="item.index" class="list-item">
											<OptionalRadio
													:checked="item.optional_value" ref="optional_processing1"
													@selectHandle='selectHandle' :id="item.index" :type="'optional_processing'"
													:name="item.opt_name"
													v-if="item.type === 'checkbox'">
											</OptionalRadio>
											<OptionalRange
													ref="optional_processing1" @rangeHandle="rangeHandle" :id="item.index"
													:type="'optional_processing'" :name="item.opt_name"
													:rangeData="item.optional_value"
													v-else-if="item.type === 'number'">
											</OptionalRange>
										</div>
										<!-- <div class="list-item">

																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																										</div> -->
									</div>
								</div>
							</div>
							<div class="filter-item model">
								<div class="label">生成型号：</div>
								<div class="data-box">
                  <span class="light-color">{{ selectionData.production_model || '---' }}

                    <button v-show="selectionData.production_model" class="copy-btn" type="button"
                            @click="doCopy" style="background-color: #fdd000 ;color: #000;">复制</button>
                  </span>
								</div>
							</div>
						</div>
						<div v-if="selectionErrorText.length > 0" class="selectionErrorText"><i class="el-icon-warning"></i>{{
								selectionErrorText
							}}
						</div>
						<div class="num-box">
							<div class="label">购买数量：</div>
							<div class="data-box">
								<el-input-number
										size="medium" @change="numberChange" v-model="num" :min="1" :max="100000"
										label="描述文字"></el-input-number>
							</div>
						</div>
						<div class="price-box">
							<div
									v-if="ask_price_type != 'quotation'" @click="askPrice"
									:class="[selectionData.production_model ? 'allowed' : 'no-allow', 'baojia']">询价
							</div>
							<div
									v-else @click="quotekPrice"
									:class="[selectionData.production_model ? 'allowed' : 'no-allow', 'baojia']">报价
							</div>
							<div class="price-detail">
								<div class="price-item">
									<div class="label">折扣单价：</div>
									<div style="color: #D9262C" class="data"><span v-if="priceData.price">¥ </span>{{ discountPricr }}
									</div>
								</div>
								<div class="price-item">
									<div class="label">折扣单价(含税)：</div>
									<div style="color: #D9262C" class="data"><span v-if="priceData.tax_price">¥ </span>{{
											discountTaxPricr
										}}
									</div>
								</div>
							</div>
							<div class="price-detail">
								<div class="price-item">
									<div class="label">合计：</div>
									<div style="color: #D9262C" class="data"><span
											v-if="priceData.price">¥ </span>{{ priceData.price ? discountPrice : "" }}
									</div>
								</div>
								<div class="price-item">
									<div class="label">发货天数：</div>
									<div class="data">{{ priceData.ship_days }}</div>
								</div>
							</div>
						</div>
						<!-- kaishi -->
						<div class="actions">
							<a
									class="btn zx" target="_blank" href="http://wpa.qq.com/msgrd?v=3&uin=800090801&site=qq&menu=yes"
									style="border-color: #D9262C;color: #D9262C">立即咨询</a>
							<div
									:class="[userInfo.company_admin_id === 0 ? 'allowed' : 'no-allow', 'btn buy']" @click="buyNow"
									style="background-color: #D9262C;color: #fff">立即购买
							</div>
							<div
									:class="[userInfo.company_admin_id === 0 ? 'allowed' : 'no-allow', 'btn', 'add']"
									style="background-color: #fdd000; color: black;" @click="addShopingCard">
								加入购物车
							</div>
							<div @click="collectHandle" style="border-color: #D9262C;color: #D9262C"
							     :class="[userInfo.company_admin_id === 0 ? 'allowed' : 'no-allow', 'btn', 'btn-collect', selectionData && selectionData.is_collect == 1 ? 'collected' : '']">
								<span class="icon iconfont icon-shoucang"></span>
								<span>{{ selectionData.is_collect === 1 ? '已收藏' : '收藏' }}</span>
							</div>
						</div>


					</div>
				</div>


				<!-- jieshu -->
			</div>
		</div>
	</div>
</template>
<script>
import MainHeader from '@/components/header/main.vue'
import CommonTop from '@/components/commonTop/index.vue'
import SelectBox from '@/components/detail/selectBox'
import RangeBox from '@/components/detail/range'
import OptionalRadio from '@/components/detail/optionalRadio'
import OptionalRange from '@/components/detail/optionalRange'
import PicZoom from '@/components/piczoom'
import {swiper, swiperSlide} from 'vue-awesome-swiper'
import {InputNumber, Select, Option} from 'element-ui'
import {addShopingCart, buyNow} from '@/api/shoping.js'
import Vue from 'vue'
import {mapActions} from 'vuex'

Vue.use(InputNumber)
Vue.use(Select)
Vue.use(Option)
export default {
	components: {
		MainHeader,
		CommonTop,
		swiper,
		swiperSlide,
		SelectBox,
		RangeBox,
		OptionalRadio,
		OptionalRange,
		PicZoom
	},
	data() {
		const _this = this
		return {
			id: null,
			navData: {},
			discountPrice: "",//折扣后的价格
			selectionErrorText: "",//选型错误后的提示
			goods_discount: [],
			picSwiperOptions: {
				slidesPerView: 4,
				spaceBetween: 10,
				speed: 500,
				freeMode: false,
				// spaceBetween: 10,
				// 自动轮播
				autoplay: false,
				// 开启循环模式
				loop: false,
				on: {
					click: function (e) {
						if (this.clickedIndex || this.clickedIndex === 0) {
							_this.picIndex = this.clickedIndex
						}
					}
				}
			},
			num: 1,
			goodsDetail: {},
			picIndex: 0,
			recommendList: [],
			goodsOptions: [],
			relateIndex: 0,
			relateTabIndex: 0,
			selectionData: {},
			isShowOptional: false,
			isShowOptionalBtn: false,
			params: {
				goods_id: '',
				pro_code_id: '',
				params_selection: [],
				pro_selection: [],
				optional_processing: []
			},
			paramsLength: 0,
			priceData: {},
			ask_price_type: '',
			params_selection_values: {},
			pro_selection_values: {},
			pro_code_value: '',
			optional_processing_values: {},
			iframeSrc: '',
			optIndex: -1,
			optTabIndex: 0,
			optTabIndex1: 0,
			optTabIndex2: 0,
			pageActive: false,
			checkNumInRangeBool: false,
			moneyobj: {},
			pro_selection_show: false,
			leftPageKey: 1,
			selectionResTemp: null,
		}
	},
	computed: {
		userInfo() {
			return this.$store.state.userInfo
		},
		productionModelComputed() {

			const params = JSON.parse(JSON.stringify(this.params))
			if (params.pro_code_id && params.params_selection.length + params.pro_selection.length === this.paramsLength) {
				let str = [];
				this.selectionData.pro_selection.forEach((item, index) => {
					if (item.var_type == 1) {
						let selectionval = this.pro_selection_values['pro_selection_' + item.id];
						params.pro_selection.forEach(val => {
							if ((item.param_type == 1 || item.param_type == 2) && (val.name == item.product_param)) {
								if (selectionval != "无") {
									str.push(`${item.product_param}${selectionval}`)
								}
							}
						})
					}
				})

				this.selectionData.optional_processing.forEach((item, index) => {

					if (item.type == "number") {
						let arr = []
						item.optional_value.map(i => {
							if (i.input_value) {
								arr.push(`${i.value_name}${i.input_value}`)
							}
						})
						if (arr.length > 0) {
							str.push(...arr)
						}
					} else if (item.type == "checkbox" && item.optional_value) {
						str.push(item.opt_name)
					}
				})

				const procodeLidt = this.selectionData.pro_code;
				for (let i = 0; i < procodeLidt.length; i++) {
					if (procodeLidt[i].id == this.params.pro_code_id) {
						str.unshift(procodeLidt[i].pro_code)
						break
					}
				}
				//this.askPrice()
				//this.selectionData.production_model = str.join("-").replace(/[\u4e00-\u9fa5]/g, "")
				return str.join("-").replace(/[\u4e00-\u9fa5]/g, "")

			} else {
				return ""
			}

		},
		discountPricr() {
			let price = this.getBit(this.priceData.discount * this.priceData.price);
			if (isNaN(price)) {
				return ""
			} else {
				return price
			}
		},
		discountTaxPricr() {
			let taxprice = this.getBit(this.priceData.discount * this.priceData.tax_price)
			if (isNaN(taxprice)) {
				return ""
			} else {
				return taxprice
			}
		}
	},
	mounted() {
		this.$nextTick(function () {
			const _this = this
			window.onscroll = function () {
				const scrollTop = window.pageYOffset || document.body.scrollTop || document.documentElement.scrollTop
				// console.log(scrollTop)
				if (scrollTop >= 600) {
					_this.pageActive = true
					_this.leftPageKey++
				} else {
					_this.pageActive = false
				}
			}
		})
	},
	watch: {
		'$route.query': {
			async handler(val) {
				if (val) {
					Object.assign(this.$data, this.$options.data.call(this))
					this.id = this.$route.query.id
					this.production_model = this.$route.query.production_model
					this.params.goods_id = this.$route.query.id
					this.getGoodsDetail()
					this.recommendGoods()
					this.getGoodsOptions()
					await this.getSelection();
					if (this.$route.query.code) {
						const code = parseInt(this.$route.query.code);
						this.$refs.pro_code_id.valueSelect = code
						await this.selectHandle('pro_code_id', code);
						if (this.$route.query.selection) {
							try {
								const selection = JSON.parse(this.$route.query.selection)
								for (const key in selection) {
									const item = this.selectionData.pro_selection.find(i => i.id == key)
									if (item.param_type == 1) {
										this.selectHandle('pro_selection', {
											type: 'select',
											value: selection[key],
											name: item.product_param,
											id: parseInt(key),
											goods_pro_params_id: code,
											var_type: 1
										});
									} else if (item.param_type == 2) {
										this.rangeHandle('pro_selection', {
											type: 'number',
											value: selection[key],
											name: item.product_param,
											id: parseInt(key),
											goods_pro_params_id: code,
											var_type: 1
										})
									}
								}
							} catch (error) {
								console.log(error);
							}
						}
					}
				}
			},
			deep: true,
			immediate: true,
		},
		params: {
			handler(val) {
				console.log(val.pro_code_id, this.selectionData.pro_code, 12345678)
				let selectionData = JSON.parse(JSON.stringify(this.selectionData))
				let CODE = ''
				if (val.pro_code_id) {
					selectionData.pro_code.forEach(item => {
						if (item.id == val.pro_code_id) {
							CODE = item.pro_code
						}
					})
				}
				var options = ''
				val.pro_selection.forEach(item => {
					options += ',{' + item.name + '=' + item.value + '}'
				})
				console.log(options, CODE, 232323232323)
				this.iframeSrc = 'https://penglong-embedded.qa.partcommunity.com/?info=penglong/penglong1/aa/aa001_aa004.prj&varset={CODE=' + CODE + '}' + options + '&hidePortlets=navigation&showPortlets=preview'
				console.log(this, this.iframeSrc)
				// let url = 'https://penglong-embedded.qa.partcommunity.com/?info=penglong/penglong1/aa/aa001_aa004.prj&varset={CODE=AA003},{D=20}&hidePortlets=navigation&showPortlets=preview'
			},
			deep: true
		}
	},
	methods: {
		...mapActions([
			'setCarNumber'
		]),
		checkRange() {
			this.priceSort(JSON.parse(JSON.stringify(this.selectionData.pro_selection)))
			let testArr = [];
			for (let i = 0; i < this.selectionData.pro_selection.length; i++) {
				let item = this.selectionData.pro_selection[i]
				if (item.param_type == 2 && item.var_type === 1) {

					let val = this.pro_selection_values['pro_selection_' + item.id];
					window[item.product_param] = isNaN(val) ? val : parseFloat(val)
					if (item.formula) {
						testArr.push({
							formula: [...item.formula.split(",")],
							prompt: item.prompt,
						})
					}
				}
			}
			for (let i = 0; i < testArr.length; i++) {
				for (const gonshi of testArr[i].formula) {
					if (!eval(gonshi)) {
						console.log("特征算法不匹配==================================", gonshi)
						let message = `${testArr[i].prompt}`
						this.$message({
							message,
							type: 'error'
						})
						this.selectionErrorText = message
						return true;
					}
				}
			}
			this.selectionErrorText = ""
			return false;
		},
		changeImgWidth: (val) => {

			return val.replace(/<img[^>]*>/gi, function (match, capture) {
				// console.log('capture', capture);
				let matchRes = match.replace(/(style="(.*?)")|(width="(.*?)")|(height="(.*?)")/ig, 'width=1000');
				return matchRes;
			})
		},
		dealTZSF(str, colName, colNameVal, rowName) {

			colNameVal = colNameVal.map(item => isNaN(item) ? item : parseFloat(item))
			if (str.split("\n").length > 1 && !str.endsWith("\n")) {
				str += "\n"
			}
			colName.map((item, index) => {
				window[item] = colNameVal[index]
			})

			const newstr = str.replaceAll("THEN", "")
					.replaceAll(".AND.", " && ")
					.replaceAll(".OR.", " || ")
					.replaceAll(".EQ.", " == ")
					.replaceAll(".GE.", " >= ")
					.replaceAll(".LE.", " <= ")
					.replaceAll(".GT.", " > ")
					.replaceAll(".LT.", " < ")
					.replaceAll(".NE.", " != ")
					.replaceAll("ELSEIF", "if")
					.replace("ENDIF", "")
					.replaceAll("IF", "if")
					.replace("ELSE", "else")
			let bianliang;
			try {
				const newarr = newstr.match(/.*\n/g); //此处 \n 或 \r
				let newarr1 = newstr;
				if (newarr) {
					newarr1 = newarr.map((str, index) => {

						bianliang = str.match(/\s*([A-Z0-9\u4e00-\u9fa5]+)\s*=\s*'?(\S+)'?\s*\n/)
						if (index == 1 && bianliang) {
							window[bianliang[1]] = null;
						}
						if (bianliang && !bianliang[2].endsWith("'")) {
							str = ` ${bianliang[1]}='${bianliang[2]}'\n`
						}
						if (str.startsWith("if") || str.startsWith("else")) {
							return str.replace(/[\n\r\s]+$/, "")
						} else {
							return str.replace(/[\n\r\s]+$/, ";")
						}
					})//.join("");
					let evalstr = ""
					for (let i = 0; i < newarr1.length; i++) {
						if (newarr1[i].startsWith("if")) {
							let evalResult = eval(evalstr);
							if (evalResult !== undefined) {
								return evalResult
							} else {
								evalstr = newarr1[i]
							}
						} else if (newarr1[i].startsWith("else")) {
							let evalResult = eval(newarr1[i + 1]);
							return evalResult
						} else {
							evalstr += newarr1[i];
						}

						if (i == newarr1.length - 1) {
							let evalResult = eval(evalstr);
							if (evalResult !== undefined) {
								return evalResult
							}
						}
					}
					return undefined;
				} else {
					bianliang = newstr.match(/(\s+)?([A-Z0-9\u4e00-\u9fa5]+)\s*=\s*'?([a-zA-Z0-9,\u4e00-\u9fa5]+).*/)
					if (bianliang) {
						bianliang[1] = bianliang[2];
						window[bianliang[1]] = null;
					}
				}
				let evalResule = eval(newarr1)
				return evalResule;
			} catch (error) {
				console.log(error)
				this.$message.error(`${rowName}的特征算法解析错误`);
				return null;
			}
		},
		optHandle(index) {
			console.log(index)
			if (this.optIndex === index) {
				return false
			}
			this.optIndex = index
			this.optTabIndex = 0
			this.optTabIndex1 = 0
			this.optTabIndex2 = 0
		},
		obtTabHandle(index) {
			console.log(index)
			this.optTabIndex = index
		},
		obtTabHandle1(index) {
			console.log(index)
			this.optTabIndex1 = index
			this.optTabIndex = 0
		},
		obtTabHandle2(index) {
			console.log(index)
			this.optTabIndex2 = index
			this.optTabIndex = 0
		},
		paramsReset() {
			this.ask_price_type = ""
			const keys1 = Object.keys(this.params_selection_values)
			console.log(this.params_selection_values)
			keys1.forEach(item => {
				this.params_selection_values[item] = ''
			})
			const keys2 = Object.keys(this.pro_selection_values)
			keys2.forEach(item => {
				//this.pro_selection_values[item] = ''
				this.pro_selection_values[item] = ""
			})
			this.selectionErrorText = ""
			this.pro_code_value = ''
			const keys3 = Object.keys(this.optional_processing_values)
			keys3.forEach(item => {
				this.optional_processing_values[item] = ''
				//console.log(item)
			})
			this.params = {
				goods_id: this.$route.query.id,
				pro_code_id: '',
				params_selection: [],
				pro_selection: [],
				optional_processing: []
			}
			//console.log(this.$refs.pro_code_id)
			this.$refs.pro_code_id.init()
			this.$refs.pro_code_id1.init()
			this.$refs.pro_selection.forEach(item => {
				item.init()
			})
			this.$refs.pro_selection1.forEach(item => {
				item.init()
			})
			this.$refs.params_selection.forEach(item => {
				item.init()
			})
			this.$refs.params_selection1.forEach(item => {
				item.init()
			})
			this.getSelection()
		},
		jumpDetail(item) {
			const {href} = this.$router.resolve({path: `/goods/detail?id=${item.id}`})
			window.open(href, '_blank')
		},
		async numberChange() {

			this.ask_price_type = '';
			if (this.selectionData.production_model) {
				if (Number.isInteger(this.num)) {

					if (
							this.goods_discount.some(
									item => item.ask_price_type == 0 && item.min <= this.num && item.max >= this.num
							)
					) {
						this.ask_price_type = 'auto_caclculate'
						this.askPrice(this.priceData.price)
					} else {
						this.priceData = {}
						this.ask_price_type = 'quotation'
						return
					}

				} else {
					this.$message.error({
						message: '购买数量必须是整数',
						type: 'error'
					});
				}
			}
		},
		quotekPrice() {
			this.$router.push({path: '/quote', query: {production_model: this.productionModelComputed, num: this.num}})
		},
		addShopingCard() {
			if (this.userInfo.company_admin_id > 0) {
				return false
			}
			if (!this.selectionData.production_model) {
				this.$message.error('请选择产品参数')
				return false
			}
			if (this.checkRange()) {
				return false
			}
			//计算商品价格  如果有的话
			const priceObj = {};
			console.log(this.goods_discount)
			if (this.priceData.total_price) {
				Object.assign(priceObj, this.priceData)
			} else {
				Object.assign(priceObj, this.getPrice())

			}
			console.log(priceObj)

			//console.log(priceObj,this.priceData,this.priceData.price)
			addShopingCart({
				goods_id: this.id,
				production_model: this.productionModelComputed,
				quantity: this.num,
				price: priceObj.price,
				tax_price: priceObj.tax_price,
				total_price: priceObj.total_price
			}).then((res) => {
				console.log(res, ":::加入购物车：：：")
				if (res.id) {
					this.$message.success('添加成功')
					this.setCarNumber()
				}
			})
		},
		buyNow() {
			if (this.userInfo.company_admin_id > 0) {
				return false
			}
			if (!this.selectionData.production_model) {
				this.$message.error('请选择产品参数')
				return false
			}
			if (this.checkRange()) {
				return false
			}
			console.log(this.priceData.price)
			if (!this.priceData.price) {
				if (this.ask_price_type != 'quotation') {
					this.$message.error('请先点击询价按钮')
				} else {
					this.$message.error('该商品已超出最大购买数量，请转报价单进行报价')
				}
				return false
			}
			buyNow({
				goods_id: this.id, production_model: this.productionModelComputed, quantity: this.num,
				price: this.priceData.price, tax_price: this.priceData.tax_price, total_price: this.priceData.total_price
			}).then(res => {
				console.log(res, "::::立即购买：：：")
				this.$router.push({path: '/user/shopping/order', query: res || {}})
			})
		},
		// 收藏
		async collectHandle() {
			if (this.userInfo.company_admin_id > 0) {
				return false
			}
			if (!this.selectionData.production_model) {
				this.$message.error({
					message: '请参数选择出型号后，再进行收藏操作',

				})
				return false
			}
			// const res = await this.$http.put(`/api/goods/collect/${this.goodsDetail.id}`, { product_model: this.selectionData.production_model })
			const res = await this.$http.post('/api/goods/collect', {
				goods_id: this.goodsDetail.id,
				product_model: this.selectionData.production_model
			})
			console.log(res)
			this.selectionData.is_collect = res.is_collect
		},
		//计算出需要执行特征算法的顺序，最后给出结果。
		priceSort(txsfArr) {

			let noSort = [];
			let needSort = [];
			let noSortArr = [];
			let oneFlag = true;
			let jiage = {}
			txsfArr.map(i => {
				if (i.var_type == 1) {
					noSort.push(i)
					noSortArr.push(i)
				} else if (i.var_type == 2) {
					if (i.quote_params.length == 0) {
						noSort.push(i)
					} else {
						needSort.push(i)
					}
				} else if (i.param_type == 3 && oneFlag) {
					Object.assign(jiage, i);
					oneFlag = false;
				}
			})
			const s = this.dataSort(noSort, needSort)//递归获取排序结果
			s.push(jiage)
			let colName = [];
			let colNameVal = [];

			for (let i = 0; i < noSortArr.length; i++) {

				colName.push(noSortArr[i].product_param)
				if (noSortArr[i].var_type == 1) {
					//页面显示的获取页面输入的结果
					if (this.pro_selection_values['pro_selection_' + noSortArr[i].id]) {
						colNameVal.push(this.pro_selection_values['pro_selection_' + noSortArr[i].id])
					} else {
						colNameVal.push(NaN)
					}
				}
			}
			for (let i = 0; i < s.length; i++) {
				if (s[i].var_type != 1) {//不显示的数据执行特征算法
					colName.push(s[i].product_param)

					if (s[i].tzsf) {
						let val = this.dealTZSF(s[i].tzsf, colName, colNameVal, s[i].product_param)
						if (i + 1 == s.length) {
							return val
						} else {
							if (val) {
								colNameVal.push(val)
							} else {
								colNameVal.push(NaN)
							}
						}

					} else {
						colNameVal.push(NaN)
					}
				}
			}
			return null;
		},
		//递归获取排序结果
		dataSort(noSort, needSort) {
			for (let i = 0; i < needSort.length; i++) {
				needSort[i].quote_params = needSort[i].quote_params.filter(j => !noSort.some(k => k.product_param == j))//过滤掉noSort的字段
				if (needSort[i].quote_params.length == 0) {
					noSort.push(needSort[i])
					needSort.splice(i, 1);
					i--;
				}
			}
			//递归调用
			if (needSort.length > 0) {
				return this.dataSort(noSort, needSort)
			} else {
				return noSort
			}
		},
		optionalPrice() {

		},
		// 获取价格
		getPrice() {
			if (this.isShowOptionalBtn && this.selectionData.production_model) {

				if (this.moneyobj.id) {
					const moneyTZSF = this.priceSort(JSON.parse(JSON.stringify(this.selectionData.pro_selection)))
					let price = Number.parseFloat(moneyTZSF)
					if (moneyTZSF && !Number.isNaN(price)) {
						price = this.getBit(price)
						this.selectionData.optional_processing.forEach((item, index) => {
							if (item.type == "number") {
								item.optional_value.map(i => {
									let input_value = this.getBit(i.input_value);
									if (i.input_value && !isNaN(input_value)) {
										price = this.accAdd(price, input_value)
									}
								})
							} else if (item.type == "checkbox" && item.optional_value) {
								price = this.accAdd(price, 1)
							}
						})
						let tax_price = this.getBit(this.accMul(price, 1.13));
						return {price: price, tax_price, total_price: this.getBit(this.accMul(tax_price, this.num))}
					}
				}
			}
			return {}
		},
		// 报价
		async askPrice(e) {
			//console.log("E==================", e)

			if (this.isShowOptionalBtn && this.selectionData.production_model && e) {
				//判断用户是否登录
				if (this.userInfo && this.userInfo.id) {
					//if (true) {
					if (this.checkRange()) {
						return false;
					}
					if (this.moneyobj.id) {
						const moneyTZSF = this.priceSort(JSON.parse(JSON.stringify(this.selectionData.pro_selection)))

						this.ask_price_type = 'auto_caclculate';
						//const moneyTZSF = 6;
						let price = Number.parseFloat(moneyTZSF)
						if (moneyTZSF && !Number.isNaN(price)) {
							price = this.getBit(price)
							this.selectionData.optional_processing.forEach((item, index) => {
								if (item.type == "number") {
									item.optional_value.map(i => {
										let input_value = this.getBit(i.input_value);
										if (i.input_value && !isNaN(input_value)) {
											price = this.accAdd(price, input_value)
										}
									})
								} else if (item.type == "checkbox" && item.optional_value) {
									price = this.accAdd(price, 1)
								}
							})
							let tax_price = this.getBit(this.accMul(price, 1.13));

							let discount = 1;

							for (let i = 0; i < this.goods_discount.length; i++) {
								if (this.goods_discount[i].min <= this.num && this.goods_discount[i].max >= this.num) {
									discount = this.goods_discount[i].discount
									break;
								}
							}
							this.discountPrice = this.getBit(this.accMul(this.accMul(tax_price, this.num), discount));
							//console.log(discount,this.getBit(this.accMul(this.accMul(tax_price, this.num),discount)),this.getBit(this.accMul(tax_price, this.num)))
							this.priceData = {
								price: price,
								tax_price,
								discount,
								total_price: this.getBit(this.accMul(tax_price, this.num)),
								ship_days: this.selectionData.ship_days,
								ask_price_type: "auto_caclculate"
							}
						} else {
							this.ask_price_type = 'quotation'
							this.$alert('系统无法计算价格，请点击“报价”获取价格', '提示', {
								confirmButtonText: '确定',
								customClass: 'my-alert',
								callback: action => {
									console.log(action)
								}
							})
						}
					} else {
						this.ask_price_type = 'quotation'
						this.$alert('系统无法计算价格，请点击“报价”获取价格', '提示', {
							confirmButtonText: '确定',
							customClass: 'my-alert',
							callback: action => {

							}
						})
					}
				} else {
					this.$alert('请先登录', '提示', {
						confirmButtonText: '确定',
						callback: action => {
							if ("confirm" == action) {
								this.$router.push("/login")
							}
						}
					})

				}
			}
		},
		accMul(arg1, arg2) {
			var m = 0, s1 = arg1.toString(), s2 = arg2.toString();
			try {
				m += s1.split(".")[1].length
			} catch (e) {
			}
			try {
				m += s2.split(".")[1].length
			} catch (e) {
			}
			return Number(s1.replace(".", "")) * Number(s2.replace(".", "")) / Math.pow(10, m)
		},
		//向下取整两位小数点
		getBit(value, bit = 4) {
			let str = value.toString();
			let strIndex = str.indexOf(".");
			if (strIndex == -1) return value
			str = str.substring(0, strIndex + bit)
			let num = Number.parseFloat(str);
			return Math.round((num) * 100) / 100;
		},
		accAdd(arg1, arg2) {
			var r1, r2, m;
			try {
				r1 = arg1.toString().split(".")[1].length
			} catch (e) {
				r1 = 0
			}
			try {
				r2 = arg2.toString().split(".")[1].length
			} catch (e) {
				r2 = 0
			}
			m = Math.pow(10, Math.max(r1, r2))
			return (arg1 * m + arg2 * m) / m
		},

		// 复制
		doCopy() {
			const _this = this
			this.$copyText(this.productionModelComputed).then(function (e) {
				_this.$message.success({
					message: '产品型号信息复制成功！',

				});
				console.log(e)
			}, function (e) {
				_this.$message.error
				({
					message: '产品型号复制失败',

				})
				console.log(e)
			})
		},
		// 跳转下载列表页
		jumpDownLoad() {
			this.$router.push({path: '/goods/download', query: {id: this.id}})
		},

		// 下拉参数处理
		async selectHandle(type, data) {
			console.log(type, data, 99999999)
			if (type === 'params_selection') {
				this.params[type] = this.params[type].filter((item => {
					return item.selection_pid !== data.selection_pid
				}))
				if (data.selection_id) {
					this.params[type].push(data)
					this.params_selection_values['params_selection_' + data.selection_pid] = data.selection_id
				}
				console.log(this.params_selection_values)
				this.getSelection()
			} else if (type === 'pro_code_id') {
				this.params[type] = data
				// console.log(data, 56789)
				this.pro_code_value = data
				this.pro_selection_show = false
				//将下拉框有的值赋值
				await this.getSelection()
			} else if (type === 'pro_selection') {

				this.params[type] = this.params[type].filter((item) => {

					return item.id && item.id !== data.id && data.var_type == 1
				})
				// console.log(this.selectionData.pro_selection, this.params[type].filter((item) => { return item.goods_pro_params_id !== data.goods_pro_params_id}), 888)
				//this.pro_selection_values['pro_selection_' + data.id] = data.value
				this.pro_selection_values[`pro_selection_${data.id}`] = data.value;
				if (data.value) {
					this.params[type].push(data)
				}
				this.getSelection(1)
			} else if (type === 'optional_processing') {

				for (let i = 0; i < this.selectionData.optional_processing.length; i++) {
					if (this.selectionData.optional_processing[i].index == data.id) {
						this.selectionData.optional_processing[i].optional_value = data.input_value
						this.$forceUpdate();
						break;
					}
				}
				//计算特征算法 算出价格  将参数加到生成型号里

				// console.log(data)
			}

		},
		// 下拉删除操作
		clearHandle(type, data) {

			if (type === 'params_selection') {
				this.params[type] = this.params[type].filter((item => {
					return item.selection_pid !== data.selection_pid
				}))

				this.$refs.params_selection.forEach(item => {

					if (item.selection_pid == data.selection_pid) {
						this.params_selection_values[`params_selection_${data.selection_pid}`] = ""
						//item.init()
					}
				})
				//清空下面选项

				this.ask_price_type = ""

				const keys2 = Object.keys(this.pro_selection_values)
				keys2.forEach(item => {
					//this.pro_selection_values[item] = ''
					this.pro_selection_values[item] = ""
				})
				this.$refs.pro_selection.forEach(item => {
					item.init()
				})
				this.$refs.pro_selection1.forEach(item => {
					item.init()
				})

				const keys3 = Object.keys(this.optional_processing_values)
				keys3.forEach(item => {
					this.optional_processing_values[item] = ''
					//console.log(item)
				})
				this.params.optional_processing = [];

				this.pro_code_value = ''
				// this.params = {
				//   goods_id: this.$route.query.id,
				//   pro_code_id: '',
				//   params_selection: [],
				//   pro_selection: [],
				//   optional_processing: []
				// }
				this.params.pro_code_id = "";
				this.params.pro_selection = [];
				this.pro_selection_show = false;
				this.getSelection()

			} else if (type === 'pro_code_id') {
				this.paramsReset()
			} else if (type === 'pro_selection') {

				this.params[type] = this.params[type].filter((item) => {
					return item.id !== data.id
				})
				this.$refs.pro_selection.forEach(item => {

					if (item.id == data.id) {
						this.pro_selection_values[`pro_selection_${data.id}`] = ""
						//item.init()
					}
				})
				this.getSelection(1)
			} else if (type === 'optional_processing') {
				//this.params[type] = this.params[type].filter((item) => { return item.id !== data.id })
				//this.getSelection()
			}

		},
		// 数值范围变化
		rangeHandle(type, data) {

			console.log(111111, type, data)
			if (type === 'pro_selection') {

				this.params[type] = this.params[type].filter((item) => {
					return item.id !== data.id
				})
				if (data.value) {
					this.params[type].push(data)
					this.pro_selection_values['pro_selection_' + data.id] = data.value
				}
				// 重新排序
				let arr = []
				this.selectionData.pro_selection.forEach(item => {
					this.params[type].forEach(val => {
						if (val.name === item.product_param) {
							arr.push(val)
						}
					})
				})
				this.params[type] = arr
				this.getSelection()
			} else if (type === 'optional_processing') {

				// this.params[type] = this.params[type].filter((item) => { return item.id !== data.id })

				// this.optional_processing_values['optional_processing_' + data.id] = data.optional_value
				// console.log(this.optional_processing_values['optional_processing_' + data.id], 12345678)
				// if (data.optional_value.length > 0) {
				//   this.params[type].push(data)
				// }
				for (let i = 0; i < this.selectionData.optional_processing.length; i++) {
					if (this.selectionData.optional_processing[i].index == data.id) {
						this.selectionData.optional_processing[i].optional_value = JSON.parse(JSON.stringify(data.optional_value))
						this.$forceUpdate();
						break;
					}
				}
			}

		},
		checkOption() {

			//1、在参数不完整的情况下发送请求；2、在参数完整的时候不发送请求； 3、在参数完整时需要检测数据是在范围内；
			let boolall = true;
			const params = JSON.parse(JSON.stringify(this.params))
			// console.log(params.params_selection.length + params.pro_selection.length, this.paramsLength)
			this.dealproSelection(params)
			//item.param_type == 2 && item.var_type === 1
			//this.selectionData.params_selection
			if (params.pro_code_id && params.params_selection.length + params.pro_selection.length === this.paramsLength) {
				//参数完整时，判断是否有错误选项，
				boolall = false
				//再走一遍特征算法

				let tempbool = true;
				let str = [];
				this.selectionData.pro_selection.forEach((item, index) => {
					if (item.var_type == 1) {
						let selectionval = this.pro_selection_values['pro_selection_' + item.id];
						params.pro_selection.forEach(val => {

							if ((item.param_type == 1 || item.param_type == 2) && (val.name == item.product_param)) {
								str.push(`${item.product_param}${selectionval}`)
							}
							if (val.name == item.product_param && item.param_type == 2) {
								const data = this.dealTypeTwo(item)

								const {checkNumInRangeBool} = this.isInRnnge(data.min, data.max, data.step, data.addtion, selectionval, false)

								this.$set(this.selectionData.pro_selection[index], "specific_param", data)
								if (!checkNumInRangeBool) {
									tempbool = false
								}
							} else if (val.name == item.product_param && item.param_type == 1) {
								//清楚选项
								this.$set(this.selectionData.pro_selection[index], "specific_param", [{
									goods_pro_params_id: item.goods_param_id,
									id: item.id,
									max_formula: "",
									min_formula: "",
									select_value: selectionval
								}])
							}
						})
					}

				})
				//检查参数是否完整 完整则计算出型号
				this.checkNumInRangeBool = tempbool

				if (tempbool) {
					const colVal = []
					const colName = []

					this.selectionData.pro_selection.map(item => {
						colName.push(item.product_param)
						if (!this.pro_selection_values['pro_selection_' + item.id]) {
							colVal.push(NaN)
						} else {
							colVal.push(this.pro_selection_values['pro_selection_' + item.id])
						}
					})
					//检查是否有需要过滤掉的数据，有则过滤掉。
					let hideArr = [];//数组是要删除的下标
					this.selectionData.pro_selection.map(item => {
						if (item.filter_tzsf && item.filter_quote_params) {
							let TZSFResult = `${this.dealTZSF(item.filter_tzsf, colName, colVal, item.product_param)}`
							if (TZSFResult) {
								let showarr = TZSFResult.replaceAll("\"", "").split(",");
								let allArr = item.filter_quote_params.split(",")
								allArr = allArr.filter(i => !showarr.some(j => j == i))//不显示的产品型号。
								hideArr.push(...allArr)
							}
						}
					})

					if (hideArr.length > 0) {
						hideArr = Array.from(new Set(hideArr));//去重
						for (let i = 0; i < this.selectionData.pro_selection.length; i++) {
							if (hideArr.some((j => j == this.selectionData.pro_selection[i].product_param))) {
								this.selectionData.pro_selection.splice(i, 1);
								this.paramsLength--;
								i--;
							}
						}

						this.paramsLength = params.params_selection.length + this.selectionData.pro_selection.filter(i => i.param_type != 3 && i.var_type == 1).length

					}

					//计算出型号
					//  params.pro_code_id &&  params.pro_selection
					const procodeLidt = this.selectionData.pro_code;
					for (let i = 0; i < procodeLidt.length; i++) {
						if (procodeLidt[i].id == this.params.pro_code_id) {
							str.unshift(procodeLidt[i].pro_code)
							break
						}
					}
					this.selectionData.production_model = str.join("-").replace(/[\u4e00-\u9fa5]/g, "")
					this.isShowOptionalBtn = true
				} else {
					this.selectionData.production_model = ""
					this.isShowOptionalBtn = false
				}

				console.log("===========================", this.selectionData.production_model)
			}

			this.$forceUpdate()
			return boolall
		},
		dealproSelection(params) {
			let bool = false;//值正常
			if (this.selectionData.pro_selection) {
				this.selectionData.pro_selection.forEach((item, index) => {
					if (item.var_type == 1) {
						params.pro_selection.forEach(val => {
							if (val.name == item.product_param && val.type == "number") {
								//只处理range框
								const {addtion = [], max, min, step} = item.specific_param;

								if (Number.parseFloat(val.value) >= min && Number.parseFloat(val.value) <= max) {
									bool = this.isInRnnge(min, max, step, addtion, Number.parseFloat(val.value), false).checkNumInRangeBool
								} else if (!addtion.some((i) => i == Number.parseFloat(val.value))) {
									bool = false
								}
								if (!bool) {
									//处理不在范围或者额外添加的值
									this.selectionData.pro_selection[index].specific_param.inrange = true
								} else {
									//值正常
									this.selectionData.pro_selection[index].specific_param.inrange = false
								}
							}
						})
					}

				})
			}

			return bool
		},
		getDateToDealTZSF(item, bool = false) {//第二个参数为是否开启辅助变量校验
			//获取处理数据，运行特征算法，返回结果
			const colVal = []
			const colName = []
			this.selectionData.pro_selection.forEach(item => {
				colName.push(item.product_param)
				if (!this.pro_selection_values['pro_selection_' + item.id]) {
					colVal.push(NaN)
				} else {
					colVal.push(this.pro_selection_values['pro_selection_' + item.id])
				}
			})
			let TZSFResult;
			if (item.tzsf) {
				TZSFResult = this.dealTZSF(item.tzsf, colName, colVal, item.product_param)
			}
			return TZSFResult
		},
		dealTypeTwo(item) {

			let TZSFResult = this.getDateToDealTZSF(item, false)

			let inrange = false
			let addtion = []

			let initRange = {min: item.specific_param.min, max: item.specific_param.max, step: item.specific_param.step}
			const temp = {goods_pro_params_id: item.goods_param_id, id: item.id, addtion, inrange}

			Object.assign(temp, {
				max: Number.parseFloat(initRange.max),
				min: Number.parseFloat(initRange.min),
				step: Number.parseFloat(initRange.step)
			})
			if (this.pro_selection_values['pro_selection_' + item.id]) {

				//当前有值，判断是否在范围内
				let val = this.pro_selection_values['pro_selection_' + item.id];
				let {checkNumInRangeBool} = this.isInRnnge(temp.min, temp.max, temp.step, addtion, Number.parseFloat(val), false)
				//inrange=checkNumInRangeBool;
				Object.assign(temp, {inrange: !checkNumInRangeBool})

			}

			if (TZSFResult && TZSFResult != "0") {
				//算出重合区间 改变step值，添加其他值。
				TZSFResult = TZSFResult.toString();

				TZSFResult = `[${TZSFResult}]`
				addtion.push(...JSON.parse(TZSFResult.replace(/\[\d+(\.\d+)?\:\d+(\.\d+)?\/\d+(\.\d+)?\]/, '[]')).flat(Infinity))
				//范围值以外额外允许的值
				let {isMatch, min, max, step} = this.getRangeObj(TZSFResult, /(\d+(\.\d+)?):(\d+(\.\d+)?)\/(\d+(\.\d+)?)/)
				if (isMatch) {
					//只选用特征算法的值
					Object.assign(temp, {min, max, step})
				}
				let result = this.isInRnnge(temp.min, temp.max, temp.step, addtion, false, true)
				//范围外去重
				addtion = [...new Set(result.addtion)]
				console.log("addtion==========================", addtion)
			}
			return temp
		},
		// 商品筛选
		async getSelection(is) {
			if (!this.checkOption()) {
				return
			}

			const params = JSON.parse(JSON.stringify(this.params))

			params.optional_processing = []
			if (this.production_model) {
				params.production_model = this.production_model
				this.production_model = null
			} else {
				params.production_model = ''
			}
			// 产品选型参数排序
			if (this.selectionData.pro_selection) {
				let pro_selection = []
				const map = new Map();
				this.selectionData.pro_selection.forEach(item => {
					if (item.var_type == 1) {
						params.pro_selection.forEach(val => {
							if (val.id == item.id) {
								map.set(val.id, {
									goods_pro_params_id: val.goods_pro_params_id,
									name: val.name,
									type: val.type,
									value: val.value,
									var_type: 1,
									id: item.id
								})
							}
						})

					}
				})

				map.forEach((val) => {
					pro_selection.push(val)
				})
				params.pro_selection = pro_selection
			}
			let res
			if (!is) {
				res = await this.$http.post('/api/goods/selection', params);
				this.selectionResTemp = JSON.stringify(res)
			} else {
				res = JSON.parse(this.selectionResTemp);
			}
			if (res.production_model !== this.selectionData.production_model) {
				this.priceData = {}
			}
			res.params_selection.forEach(item => {
				if (!this.params_selection_values['params_selection_' + item.parent_selection.id]) {
					this.params_selection_values['params_selection_' + item.parent_selection.id] = ''
				}

			})
			// res.pro_selection2=res.pro_selection2.map((item)=>{
			// 	if(item.product_param == 'A'){
			// 	item.tzsf =""
			// 	}
			// 	return item
			// })
			res.pro_selection2 = res.pro_selection2.filter(item => {
				let flag = item.var_type == 1 || item.var_type == 2 || item.param_type == 3;
				if (flag) {
					if (!this.pro_selection_values['pro_selection_' + item.id]) {
						this.pro_selection_values['pro_selection_' + item.id] = ''
						this.$set(this.pro_selection_values, `pro_selection_${item.id}`, "")
					}
				}
				return flag;
			})
			res.pro_code = res.pro_code.filter((item) => {
				return res.pro_selection2.some((j) => j.goods_param_id == item.id)
			})
			res.pro_selection2 = res.pro_selection2.filter((item) => {
				return res.pro_code.some((j) => j.id == item.goods_param_id)
			})
			const colVal = []
			const colName = []
			res.pro_selection2.map(item => {
				colName.push(item.product_param)
				if (!this.pro_selection_values['pro_selection_' + item.id]) {
					colVal.push(NaN)
				} else {
					colVal.push(this.pro_selection_values['pro_selection_' + item.id])
				}
			})
			//过滤特征算法
			let hideArr = [];
			res.pro_selection2.map(item => {
				if (item.filter_tzsf && item.filter_quote_params) {
					let TZSFResult = this.dealTZSF(item.filter_tzsf, colName, colVal, item.product_param)
					if (TZSFResult) {
						TZSFResult = `${TZSFResult}`
						let showarr = TZSFResult.replaceAll("\"", "").split(",");
						let allArr = item.filter_quote_params.split(",")
						allArr = allArr.filter(i => !showarr.some(j => j == i))//不显示的产品型号。
						hideArr.push(...allArr)
					}

				}
			})

			if (hideArr.length > 0) {
				res.pro_selection2 = res.pro_selection2.filter((item) => !hideArr.some(i => i == item.product_param))
			}

			// 获取生成型号所需要的params_selection 和 pro_selection 参数总个数
			this.paramsLength = res.params_selection.length + res.pro_selection2.filter(i => i.param_type != 3 && i.var_type == 1).length // 1 代表pro_code
			// console.log(this.paramsLength)


			res.pro_selection = res.pro_selection2.map(item => {
				let moneyBool = true;
				if (item.var_type == 1) {
					let TZSFResult;
					if (item.tzsf && item.param_type != 3) {
						TZSFResult = this.dealTZSF(item.tzsf, colName, colVal, item.product_param)
					}
					//TZSFResult = "[15,16,36,36.6,36.1,16,20,18,[34:40/0.6],36,18],18.9,20.5"//测试数据 后面删除
					item.goods_pro_params_id = item.goods_param_id
					if (item.param_type === 1) {

						let type1_arr

						if (this.params.pro_selection.some(hh => hh.id == item.id)) {
							//如果已选中只显示选中的
							type1_arr = [{
								id: item.id,
								goods_pro_params_id: item.goods_param_id,
								max_formula: "",
								min_formula: "",
								select_value: this.pro_selection_values['pro_selection_' + item.id]
							}]
						} else {
							//没选中的做处理
							let arrstr = []
							if (TZSFResult) {
								arrstr = `${TZSFResult}`;
							} else {
								arrstr = item.specific_param
							}
							type1_arr = [...new Set(arrstr.split(","))].map(i => {
								return {
									id: item.id,
									goods_pro_params_id: item.goods_param_id,
									max_formula: "",
									min_formula: "",
									select_value: i
								}
							})
						}
						item.specific_param = type1_arr
					} else if (item.param_type === 2) {
						//  1~100/1 数字

						let inrange = false
						let addtion = []

						let initRange = this.getRangeObj(item.specific_param, /(\d+(\.\d+)?)~(\d+(\.\d+)?)\/(\d+(\.\d+)?)/)
						const temp = {goods_pro_params_id: item.goods_param_id, id: item.id, addtion, inrange}
						if (initRange.isMatch) {
							Object.assign(temp, {
								max: Number.parseFloat(initRange.max),
								min: Number.parseFloat(initRange.min),
								step: Number.parseFloat(initRange.step)
							})
							if (this.pro_selection_values['pro_selection_' + item.id]) {

								//当前有值，判断是否在范围内
								let val = this.pro_selection_values['pro_selection_' + item.id];
								let {checkNumInRangeBool} = this.isInRnnge(temp.min, temp.max, temp.step, addtion, Number.parseFloat(val), false)
								//inrange=checkNumInRangeBool;
								Object.assign(temp, {inrange: !checkNumInRangeBool})

							}
						}
						//const matchStr = item.specific_param.match(/(\d+)~(\d+)\/(\d+)/)
						//let addtion = [] //范围值以外额外允许的值
						//const temp = { goods_pro_params_id: item.goods_param_id, id: item.id, addtion, inrange }
						//inrange:true
						//if (matchStr) {
						//  Object.assign(temp, { max: Number.parseFloat(matchStr[2]), min: Number.parseFloat(matchStr[1]), step: Number.parseFloat(matchStr[3]) })
						//}
						if (TZSFResult && TZSFResult != "0") {
							//算出重合区间 改变step值，添加其他值。
							TZSFResult = TZSFResult.toString();
							const matchTzsfStr = TZSFResult.match(/(\d+(\.\d+)?):(\d+(\.\d+)?)\/(\d+(\.\d+)?)/)
							TZSFResult = `[${TZSFResult}]`
							addtion.push(...JSON.parse(TZSFResult.replace(/\[\d+(\.\d+)?\:\d+(\.\d+)?\/\d+(\.\d+)?\]/, '[]')).flat(Infinity))
							//范围值以外额外允许的值
							let {isMatch, min, max, step} = this.getRangeObj(TZSFResult, /(\d+(\.\d+)?):(\d+(\.\d+)?)\/(\d+(\.\d+)?)/)
							if (isMatch) {
								//只选用特征算法的值
								Object.assign(temp, {min, max, step})
							}

							// if (matchTzsfStr) {
							//   //合并区间
							//   let min = Number.parseFloat(matchTzsfStr[1]);
							//   let max = Number.parseFloat(matchTzsfStr[3]);
							//   let step = Number.parseFloat(matchTzsfStr[5]);
							//   Object.assign(temp, { min, max, step })//只选用特征算法的值
							//   // if (matchStr) {
							//   //   Object.assign(temp, { min: Math.max(min, temp.min), max: Math.min(max, temp.max), step })
							//   // } else {
							//   //   Object.assign(temp, { min, max, step })
							//   // }
							// } else {
							//   //Object.assign(temp, { min: 0, max: 0, step: 0 })
							// }

							let result = this.isInRnnge(temp.min, temp.max, temp.step, addtion, false, true)
							//范围外去重
							addtion = [...new Set(result.addtion)]
							console.log("addtion==========================", addtion)
						}
						//根据特征算法算出区间然后合并
						item.specific_param = temp
					}
				} else if (item.param_type === 3) {
					if (moneyBool) {
						Object.assign(this.moneyobj, item)
						moneyBool = false
					}
				}
				return item;
			})
			res.pro_selection.sort((a, b) => a.sort - b.sort)

			//res.pro_selection_concat=[]
			if (res.pro_code.length > 0) {
				//有多个产品选型时合并参数
				const pro_selection_map = new Map();
				//合并下拉框 重复的，和输入框范围合并，数组存放到 pro_selection_concat
				res.pro_selection.map((item) => {
					if (item.var_type == 1) {
						if (pro_selection_map.has(item.product_param)) {
							//合并 后并重新给值
							const pro_selection_map_val = pro_selection_map.get(item.product_param)
							if (item.product_param == 1) {
								let tempMap = new Map();
								let tempArr = []
								pro_selection_map_val.specific_param.map(i => {
									tempMap.set(i.select_value, i)
								})

								item.specific_param.map((i) => {
									tempMap.set(i.select_value, i)
								})
								tempMap.forEach((val) => {
									tempArr.push(val)
								})
								item.specific_param = tempArr
								pro_selection_map.set(item.product_param, item)
							} else if (item.product_param == 2) {
								item.specific_param.max = Math.max(item.specific_param.max, pro_selection_map_val.specific_param.max)
								item.specific_param.min = Math.min(item.specific_param.min, pro_selection_map_val.specific_param.min)
								item.specific_param.addtion = [...new Set([...item.specific_param.addtion, ...pro_selection_map_val.specific_param.addtion])]
								pro_selection_map.set(item.product_param, item)
							}
						} else {
							if (this.pro_code_value) {
								item.isSelectionConcat = false
							} else {
								item.isSelectionConcat = true
							}

							pro_selection_map.set(item.product_param, item)
						}
					}
				})
				res.pro_selection_concat = [...pro_selection_map.values()];

			}

			this.params.pro_selection = params.pro_selection.filter(item => {
				return res.pro_selection2.some((rps => rps.id == item.id))
			})
			res.pro_selection2.map((item) => {
				if (item.param_type != 3 && item.var_type == 1 && this.pro_selection_values['pro_selection_' + item.id] && !this.params.pro_selection.some(i => i.id == item.id)) {
					//判断类型
					if (item.param_type === 1) {
						this.params.pro_selection.push({
							"goods_pro_params_id": item.goods_param_id,
							"name": item.product_param,
							"type": "select",
							"value": this.pro_selection_values['pro_selection_' + item.id],
							"var_type": item.var_type,
							"id": item.id
						})
					} else if (item.param_type === 2) {
						this.params.pro_selection.push({
							"goods_pro_params_id": item.goods_param_id,
							"name": item.product_param,
							"type": "number",
							"value": this.pro_selection_values['pro_selection_' + item.id],
							"var_type": item.var_type,
							"id": item.id
						})
					}

				}
			})
			const arrProcessing = [];
			let myindex = 1;
			res.optional_processing.map(item => {
				if (item.var_type == 3) {
					const obj = {id: item.id, opt_name: item.product_param}
					let sparr = [];
					if (item.param_type == 4) {
						item.specific_param.split(",").map((i) => {

							arrProcessing.push({index: myindex++, type: "checkbox", opt_name: i, optional_value: false, id: item.id})
						})
					} else if (item.param_type == 2) {
						item.specific_param.split(",").map((i) => {
							let rst = i.match(/(.+)\:(\d+(\.\d+)?)~(\d+(\.\d+)?)\/(\d+(\.\d+)?)/);
							if (rst) {
								sparr.push({
									"id": item.id,
									"processing_id": item.goods_param_id,
									"value_name": rst[1],
									"min": rst[2],
									"max": rst[4],
									"step": rst[6],
									"input_value": "",
									"index": myindex++
								})
							}
						})
						Object.assign(obj, {index: myindex++, type: "number", optional_value: sparr})
						arrProcessing.push(obj)
					}
				}
			})

			res.optional_processing = arrProcessing;

			if (this.goodsDetail.id) {
				this.$http.get(`/api/goods/${this.goodsDetail.id}`).then(({goods_discount}) => {


					if (goods_discount.some(item => item.ask_price_type == 0 && item.min <= this.num && item.max >= this.num)) {
						this.ask_price_type = "auto_caclculate"

					} else {
						this.priceData = {}
						this.ask_price_type = "quotation"
					}
					this.goods_discount = goods_discount


				});
			}

			//this.pro_selection_values = JSON.parse(JSON.stringify(this.pro_selection_values))
			this.pro_selection_show = true
			this.selectionData = res
			this.setSelectBoxOneValue()
			//this.$set(this,"selectionData",res)
			//Object.assign(this.selectionData, res)
			console.log(this.selectionData, 888888111111, this.optional_processing_values)
			this.$forceUpdate()
			this.checkOption()
		},
		//下拉框单个参数处理
		setSelectBoxOneValue() {
			const params = JSON.parse(JSON.stringify(this.params))
			if (params.pro_code_id) {
				//只有一个值时计算
				console.log(
						this.selectionData.pro_selection,
						this.selectionData.params_selection
				)

				this.selectionData.pro_selection &&
				this.selectionData.pro_selection.map(item => {
					if (item.param_type === 1 && item.var_type === 1) {
						if (item.specific_param.length == 1) {


							this.params['pro_selection'] = this.params[
									'pro_selection'
									].filter(i => {
								return i.id && i.id !== item.id && item.var_type == 1
							})

							this.pro_selection_values[`pro_selection_${item.id}`] =
									item.specific_param[0].select_value
							this.params['pro_selection'].push({
								goods_pro_params_id: item.goods_param_id,
								id: item.id,
								name: item.product_param,
								type: 'select',
								value: item.specific_param[0].select_value,
								var_type: 1
							})
						}
					}
				})

				this.selectionData.params_selection &&
				this.selectionData.params_selection.map(item => {
					if (item.child_selection.length == 1) {
						this.params['params_selection'] = this.params[
								'params_selection'
								].filter(i => {
							return i.selection_pid !== item.parent_selection.id
						})
						if (item.child_selection[0].id) {
							this.params['params_selection'].push({
								selection_id: item.child_selection[0].id,
								selection_pid: item.parent_selection.id
							})
							this.params_selection_values[
							'params_selection_' + item.parent_selection.id
									] = item.child_selection[0].id
						}
					}
				})
			}
		},
		getRangeObj(str, regx) {
			const matchTzsfStr = str.match(regx);
			let min, max, step;
			let isMatch = false;
			if (matchTzsfStr) {
				//合并区间
				isMatch = true
				min = Number.parseFloat(matchTzsfStr[1]);
				max = Number.parseFloat(matchTzsfStr[3]);
				step = Number.parseFloat(matchTzsfStr[5]);
			}
			return {isMatch, min, max, step}
		},
		getRangeObj1(str, regx) {
			const matchTzsfStr = str.match(regx);
			let min, max, step;
			let isMatch = false;
			if (matchTzsfStr) {
				//合并区间
				isMatch = true
				min = Number.parseFloat(matchTzsfStr[1]);
				max = Number.parseFloat(matchTzsfStr[2]);
				step = Number.parseFloat(matchTzsfStr[3]);
			}
			return {isMatch, min, max, step}
		},
		//判断是否在范围内,并算出额外数组
		isInRnnge(min, max, step, addtion, checkNum, isout) {

			checkNum = isNaN(checkNum) ? val : parseFloat(checkNum)
			//后两个参数分别是 需要验证的值，是否需要计算出addtion？
			let multi;//处理小数取%问题
			let checkNumInRangeBool;
			if (Number.isInteger(step)) {
				multi = 1
			} else {
				multi = Math.pow(10, step.toString().split(".")[1].length)
			}
			//去除范围内的数
			if (isout) {
				addtion = addtion.filter(m => {
					return !(m >= min && m <= max && (m * multi) % (step * multi) == 0)
				})
			}

			if (checkNum || checkNum == 0) {
				checkNumInRangeBool = addtion.some(m => m == checkNum) || (checkNum >= min && checkNum <= max && (this.accMul(checkNum, multi)) % (this.accMul(step, multi)) == 0)
			}
			return {
				addtion,
				checkNumInRangeBool
			}
		},
		// 产品参数一级切换
		relateHandle(index) {
			console.log(index)
			this.relateIndex = index
			this.relateTabIndex = 0
		},
		// 产品参数二级切换
		relateTabHandle(index) {
			this.relateTabIndex = index
		},
		// 获取产品参数
		async getGoodsOptions() {
			const res = await this.$http.get('/api/goods/params', {goods_id: this.id})
			let goodsOptions = []
			if (res !== []) {
				Object.keys(res).forEach(name => {
					goodsOptions.push({name: name, data: res[name]})
				})
			}
			let arr = [{name: '技术图档', data: []}]
			goodsOptions.forEach(item => {
				if (item.name === '技术图档-参数图' || item.name === '技术图档-材质图') {
					arr[0].data.push(item)
				} else {
					arr.push(item)
				}
			})
			if (arr[0].data[0] && arr[0].data[0].name.includes('参数图')) {
				arr[0].data.reverse()
			}
			this.goodsOptions = arr
		},
		// 获取推荐商品
		async recommendGoods() {
			const res = await this.$http.get('/api/goods/recommend', {goods_id: this.id, limit: 5})
			// console.log('推荐商品列表：', res)
			this.recommendList = res
		},
		// 获取商品详情
		async getGoodsDetail() {
			const res = await this.$http.get(`/api/goods/${this.id}`)
			// console.log('商品详情：', res)
			this.goodsDetail = res
			this.getNavData()
		},
		async getNavData() {
			const res = await this.$http.get('/common/category/nav', {category_id: this.goodsDetail.category_id})
			this.navData = res
		},
		slideHandle(type) {
			if (type === 'prev') {
				this.$refs.picSwiper.swiper.slidePrev()
			} else if (type === 'next') {
				this.$refs.picSwiper.swiper.slideNext()
			}
		},
		openModelClick() {
			if (this.selectionData && this.selectionData.model_url) {
				window.open(this.selectionData.model_url, '_blank')
			}
		}
	}
}
</script>
<style lang="scss">
.my-alert .el-button {
	background-color: #f9dfe0;
	border: #f9dfe0;
	color: #d9262c;
	/* 替换为你想要的背景颜色 */
}

.optactive {
	//background-color: #3385FF;
	background-color: #d9262c;
	color: #ffffff;
	border: 1px solid #D9262C
}

.rich-text {
	padding-right: 5px;

	img {
		width: 100%;
		object-fit: cover;
		height: 100%;
	}
}

.opt-container {
	flex: 1;
	height: 100%;
	border: 1px solid #d9262c;
	padding: 10px;
	display: flex;
	flex-direction: column;

	.opt-section {
		flex: 1;
		overflow: hidden;

		.scroll-y {

			overflow: auto;
			height: 100%;
			padding-bottom: 50px;

			&::-webkit-scrollbar {
				width: 6px;
				height: 4px;
			}

			&::-webkit-scrollbar-thumb {
				border-radius: 5px;
				-webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
				background-color: #f1d6d6;
			}

			&::-webkit-scrollbar-track {
				-webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
				border-radius: 5px;
				background-color: #FFFFFF;
			}
		}
	}
}

.opt-tab-box {
	margin-bottom: 10px;
	display: flex;

	.opt-tab-item {
		border: 1px solid #e5e5e5;
		padding: 5px 10px;
		border-radius: 1px;
		cursor: pointer;
		margin-right: 10px;

		&.active {
			background-color: #D9262C;
			border-color: #D9262C;
			color: #ffffff;
		}
	}
}

.right-section {
	width: 0;
	overflow: hidden;
	transition: all .3s;
	background-color: #ffffff;

	// width: 39%;
	// padding: 0 10px 10px 10px;
	// border-left: 10px solid #f5f5f5;
	// margin-left: 10px;
	.goods-detail {
		flex: 1;
		margin-left: 15px;

		.goods-name {
			font-size: 20px;
			font-weight: bold;
			white-space: pre;
		}

		.tools {
			display: flex;
			margin-top: 15px;

			.btn {
				width: 110px;
				height: 36px;
				font-size: 14px;
				display: flex;
				justify-content: center;
				align-items: center;
				border-radius: 3px;
				cursor: pointer;
				margin-right: 20px;

				&:hover {
					opacity: .8;
				}

				&:first-child {
					background-color: #3385FF;
					color: #ffffff;
				}

				&:nth-child(2) {
					background-color: #F5F6FB;
					margin-left: 10px;
				}
			}
		}

		.filter-container {
			.filter-item {
				display: flex;

				// padding: 15px 0;
				// border-bottom: 1px dotted #f1f1f1;
				.light-color {
					color: #3385FF;
				}

				.label {
					color: #999999;
					text-align: right;
					// width: 75px;
					text-align: right;
				}

				&.model {
					// padding: 15px 0;
					margin-top: 15px;

					.label,
					.light-color {
						line-height: normal;
					}
				}

				&.condition {
					line-height: 25px;
					margin-top: 15px;
					// padding: 10px 0;
				}

				&.parameter {
					// padding: 15px 0 15px;
					margin-top: 10px;

					.label {
						// line-height: 50px;
						padding-top: 8px;
					}

					.data-box {
						.label {
							border: 1px solid #DCDFE6;
							line-height: 28px;
							border-right: 0 none;
							padding: 0 8px;
						}
					}
				}

				&.optional-processing {
					margin-top: 10px;
					align-items: flex-start;

					&.show {
						.label {
							border-left: 2px solid #D9262C;
							color: #D9262C;
							background-color: #FDF4F4;
						}

						.data-box {
							opacity: 1;
							height: 120px;
						}
					}

					.label {
						background-color: #D9262C;
						border-left: 2px solid #D9262C;
						line-height: 30px;
						color: #ffffff;
						text-align: center;
						cursor: pointer;
						user-select: none;
					}

					.data-box {
						height: 30px;
						opacity: 0;
						background-color: #FDF4F4;
						transition: height .3s;
						overflow-y: auto;

						&::-webkit-scrollbar {
							width: 5px;
							height: 4px;
						}

						&::-webkit-scrollbar-thumb {
							border-radius: 5px;
							-webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
							background-color: #f1d6d6;
						}

						.list {
							flex: 1;
							display: flex;
							flex-wrap: wrap;
							align-items: flex-start;
							padding: 5px;
							padding-bottom: 0px;

							.list-item {
								background-color: #ffffff;
								padding: 5px 10px;
								margin-right: 5px;
								border: 1px solid #DCDFE6;
								width: auto;
								margin-bottom: 5px;
							}
						}
					}
				}

				.data-box {
					display: flex;
					flex-wrap: wrap;
					overflow: hidden;
					flex: 1;

					.copy-btn {
						border: 0 none;
						font-size: 12px;
						color: #ffffff;
						background-color: #3385FF;
						padding: 1px 5px;
						border-radius: 2px;
						cursor: pointer;
						margin-left: 10px;
					}

					.data-item {
						margin-right: 15px;
					}

					.select-box {
						display: flex;
						align-items: center;
						margin-right: 10px;
						margin-top: 10px;

						// &:nth-child(-n + 3) {
						//   margin-top: 0;
						// }
						// &:nth-child(3n) {
						//   margin-right: 0;
						// }
						// width: 230px;

						.label {
							color: #333;
							white-space: nowrap;
							// font-size: 13px;
						}

						.select-wrap {
							flex: 1;
						}
					}
				}
			}
		}

		.selectionErrorText {
			color: #D9262C;
			margin: 13px 0 0 77px;
		}

		.num-box {
			display: flex;
			align-items: center;
			margin-top: 20px;

			.label {
				color: #999999;
			}
		}

		.price-box {
			margin-top: 20px;

			.baojia {
				width: 110px;
				height: 36px;
				border-radius: 3px;
				display: flex;
				justify-content: center;
				align-items: center;
				font-size: 14px;
				color: #D9262C;
				border: 1px solid #D9262C;
				cursor: pointer;

				&.no-allow {
					cursor: not-allowed;
					opacity: .4;

					&:hover {
						opacity: .4;
					}
				}

				&:hover {
					opacity: .8;
				}
			}

			.price-detail {
				display: flex;
				margin-top: 20px;

				.price-item {
					flex: 1;
					display: flex;

					.data {
						flex: 1;
						border-bottom: 1px solid #f1f1f1;
						padding: 0 10px;
						line-height: 1.2;
						text-align: center;
					}
				}
			}
		}

		.actions {
			display: flex;
			align-items: center;
			margin-top: 20px;

			.btn {
				width: 110px;
				height: 36px;
				display: flex;
				justify-content: center;
				align-items: center;
				font-size: 14px;
				border-radius: 3px;
				margin-right: 10px;
				text-decoration: none;

				&.no-allow {
					cursor: not-allowed;
					opacity: .4;

					&:hover {
						opacity: .4;
					}
				}

				cursor: pointer;

				&:hover {
					opacity: .8;
				}

				&.zx {
					color: #3385FF;
					border: 1px solid #3385FF;
				}

				&.buy {
					background-color: #3385FF;
					color: #ffffff;
				}

				&.add {
					background-color: #ffca0a;
					color: #ffffff;
				}

				&.btn-collect {
					color: #3385FF;
					border: 1px solid #3385FF;

					&.collected {
						background-color: #3385FF;
						color: #ffffff;
					}

					.iconfont {
						font-size: 14px;
						margin-right: 5px;
					}
				}
			}
		}
	}

	.goods-name {
		font-size: 20px;
		font-weight: bold;
		margin-top: 20px;
	}

	.goods-img {
		width: 198px;
		height: 198px;
		border: 1px solid #e5e5e5;
		display: flex;
		justify-content: center;
		align-items: center;

		img {
			max-width: 100%;
			max-height: 100%;
		}
	}
}

.pic-zoom {
	.small {
		display: flex;
		justify-content: center;
		align-items: center;
		overflow: hidden;

		img {
			width: 100%;
		}
	}
}
</style>
<style lang="scss" scoped>
#goods-detail-container {
	padding-bottom: 30px;

	.breadcrumb-container {
		border-top: 1px solid #F5F6FB;
	}

	.breadcrumb {
		padding-top: 20px;
		display: flex;
		font-size: 14px;
		color: #999999FF;

		.breadcrumb-item {
			margin-right: 10px;

			a {
				color: #999999;
				text-decoration: none;
			}

			.iconfont {
				font-size: 12px;
				margin-right: 10px;
			}
		}
	}

	.goods-detail-container {
		display: flex;
		margin-top: 30px;

		.goods-covers {
			width: 400px;

			.main-pic {
				width: 400px;
				height: 400px;
				// border-radius: 3px;
				background-color: #f1f1f1;
				border: 1px solid #DFDFDF;

				// overflow: hidden;
				img {
					width: 100%;
					height: 100%;
				}
			}

			.pic-group {
				display: flex;
				align-items: center;
				justify-content: space-between;
				height: 77px;
				margin-top: 10px;

				.next,
				.prev {
					width: 20px;
					height: 100%;
					display: flex;
					align-items: center;
					justify-content: center;
					cursor: pointer;

					// background-color: #f1f1f1;
					&:hover {
						.iconfont {
							color: #3385FF;
						}
					}

					.iconfont {
						font-size: 14px;
						color: #999;
					}
				}

				.next {
					border-radius: 0 3px 3px 0;
				}

				.prev {
					border-radius: 3px 0 0 3px;
				}

				.prev .iconfont {
					transform: rotate(180deg);
				}

				.pic-swiper {
					flex: 1;
					height: 100%;
					margin: 0 10px;

					.swiper-slide {
						background-color: #f1f1f1;
						cursor: pointer;
						display: flex;
						justify-content: center;
						align-items: center;
						border-radius: 3px;
						overflow: hidden;

						&.active {
							border: 1px solid #f1d6d6;
						}

						img {
							width: 100%;
							height: 100%;
							object-fit: cover;
						}
					}
				}
			}

			.user-actions {
				display: flex;
				justify-content: flex-end;
				margin-top: 15px;

				.item {
					font-size: 14px;
					color: #666666;

					&.collect {
						color: #3385FF;
					}

					cursor: pointer;

					.iconfont {
						margin-right: 6px;
					}
				}
			}
		}

		.goods-detail {
			flex: 1;
			margin-left: 15px;

			.goods-name {
				font-size: 20px;
				font-weight: bold;
				white-space: pre;
			}

			.tools {
				display: flex;
				margin-top: 15px;

				.btn {
					width: 110px;
					height: 36px;
					font-size: 14px;
					display: flex;
					justify-content: center;
					align-items: center;
					border-radius: 3px;
					cursor: pointer;

					&:hover {
						opacity: .8;
					}

					&:first-child {
						background-color: #3385FF;
						color: #ffffff;
					}

					&:nth-child(2) {
						background-color: #F5F6FB;
						margin-left: 10px;
					}
				}
			}

			.filter-container {
				.filter-item {
					display: flex;

					// padding: 15px 0;
					// border-bottom: 1px dotted #f1f1f1;
					.light-color {
						color: #3385FF;
					}

					.label {
						color: #999999;
						text-align: right;
						width: 75px;
						text-align: right;
					}

					&.model {
						// padding: 15px 0;
						margin-top: 15px;

						.label,
						.light-color {
							line-height: normal;
						}
					}

					&.condition {
						line-height: 25px;
						margin-top: 15px;
						// padding: 10px 0;
					}

					&.parameter {
						// padding: 15px 0 15px;
						margin-top: 10px;

						.label {
							// line-height: 50px;
							padding-top: 8px;
						}

						.data-box {
							.label {
								border: 1px solid #DCDFE6;
								line-height: 28px;
								border-right: 0 none;
								padding: 0 8px;
							}
						}
					}

					&.optional-processing {
						margin-top: 10px;
						align-items: flex-start;

						&.show {
							.label {
								border-left: 2px solid #D9262C;
								color: #D9262C;
								background-color: #FDF4F4;
							}

							.data-box {
								opacity: 1;
								height: auto;
							}
						}

						.label {
							background-color: #D9262C;
							border-left: 2px solid #D9262C;
							line-height: 30px;
							color: #ffffff;
							text-align: center;
							cursor: pointer;
							user-select: none;
						}

						.data-box {
							height: 30px;
							opacity: 0;
							background-color: #FDF4F4;
							transition: height .3s;
							overflow-y: auto;
							&::-webkit-scrollbar {
								width: 5px;
								height: 4px;
							}

							&::-webkit-scrollbar-thumb {
								border-radius: 5px;
								-webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
								background-color: #f1d6d6;
							}

							.list {
								flex: 1;
								display: flex;
								flex-wrap: wrap;
								align-items: flex-start;
								padding: 5px;
								padding-bottom: 0px;

								.list-item {
									background-color: #ffffff;
									padding: 5px 10px;
									margin-right: 5px;
									border: 1px solid #DCDFE6;
									width: auto;
									margin-bottom: 5px;
								}
							}
						}
					}

					.data-box {
						display: flex;
						flex-wrap: wrap;
						overflow: hidden;
						flex: 1;
						// color: #f1d6d6;

						.copy-btn {
							border: 0 none;
							font-size: 12px;
							color: #ffffff;
							background-color: #d9262c;
							padding: 1px 5px;
							border-radius: 2px;
							cursor: pointer;
							margin-left: 10px;


						}

						.data-item {
							margin-right: 15px;
						}

						.select-box {
							display: flex;
							align-items: center;

							// width: 300px;

							&:nth-child(-n + 3) {
							}

							&:nth-child(3n) {
								margin-right: 10px;
							}


							width: 300px;
							margin-top: 10px;

							.label {
								color: #333;
								white-space: nowrap;
								// font-size: 13px;
							}

							.select-wrap {
								flex: 1;
							}
						}
					}
				}
			}

			.num-box {
				display: flex;
				align-items: center;
				margin-top: 20px;

				.label {
					color: #999999;
				}
			}

			.selectionErrorText {
				color: #D9262C;
				margin: 13px 0 0 77px;
			}

			.price-box {
				margin-top: 20px;

				.baojia {
					width: 110px;
					height: 36px;
					border-radius: 3px;
					display: flex;
					justify-content: center;
					align-items: center;
					font-size: 14px;
					color: #d9262c;
					border: 1px solid #d9262c;
					cursor: pointer;

					&.no-allow {
						cursor: not-allowed;
						opacity: .4;

						&:hover {
							opacity: .4;
						}
					}

					&:hover {
						opacity: .8;
					}
				}

				.price-detail {
					display: flex;
					margin-top: 20px;

					.price-item {
						flex: 2;
						display: flex;

						.data {
							flex: 1;
							border-bottom: 1px solid #f1f1f1;
							padding: 0 10px;
							line-height: 1.2;
							text-align: center;
						}
					}
				}
			}

			.actions {
				display: flex;
				align-items: center;
				margin-top: 20px;

				.btn {
					width: 110px;
					height: 36px;
					display: flex;
					justify-content: center;
					align-items: center;
					font-size: 14px;
					border-radius: 3px;
					margin-right: 10px;
					text-decoration: none;

					&.no-allow {
						cursor: not-allowed;
						opacity: .4;

						&:hover {
							opacity: .4;
						}
					}

					cursor: pointer;

					&:hover {
						opacity: .8;
					}

					&.zx {
						color: #3385FF;
						border: 1px solid #3385FF;
					}

					&.buy {
						background-color: #3385FF;
						color: #ffffff;
					}

					&.add {
						background-color: #ffca0a;
						color: #ffffff;
					}

					&.btn-collect {
						color: #3385FF;
						border: 1px solid #3385FF;

						&.collected {
						}

						.iconfont {
							font-size: 14px;
							margin-right: 5px;
						}
					}
				}
			}
		}
	}

	.related {
		background-color: #f5f5f5;
		padding-top: 15px;
		margin-top: 30px;

		.related-container {
			display: flex;
			width: 1200px;
			margin: 0 auto;

			&.active {
				width: calc(100vw - 40px);

				.right {
					display: none;
				}

				.right-section {
					width: 580px;
					padding: 10px 10px 10px 10px;
					border-left: 10px solid #f5f5f5;
					margin-left: 10px;
				}
			}

			.header {
				background-color: #F5F6FB;
				padding: 10px 10px;
				text-align: center;
				font-weight: 600;
				border-radius: 2px;
			}

			.left {
				flex: 1;

				.relate-content {
					// margin-top: 15px;
					background-color: #ffffff;
					padding: 0 10px;
					border-radius: 2px;
					overflow: hidden;

					.relate-header-box {

						// border-bottom: 1px solid #dbdbdb;
						.relate-cat {
							//background-color: #fbe999;
							background-color: #ececec;
							margin-left: -10px;
							margin-right: -10px;
							display: flex;

							// background-ci
							.relate-cat-item {
								cursor: pointer;
								line-height: 38px;
								padding: 0 20px;
								text-align: center;
								min-width: 110px;

								&.active {
									//background-color: #3385FF;
									background-color: #d9262c;
									color: #ffffff;
								}
							}
						}

						.relate-tab {
							display: flex;
							border-top: 1px solid rgba(255, 255, 255, 0.6);

							.relate-tab-item {
								margin-right: 15px;
								cursor: pointer;
								line-height: 38px;
								padding: 0 20px;
								text-align: center;
								min-width: 110px;

								&.active {
									color: #3385FF;
								}
							}
						}
					}

					.rich-text-container {
						background-color: #ffffff;

						padding: 15px 0 0;

						.rich-text {
							line-height: 2;
							text-align: justify;
						}
					}
				}
			}

			.right {
				margin-left: 20px;
				width: 240px;

				.list {
					.list-item {
						border-bottom: 1px solid #eee;
						cursor: pointer;
						padding: 15px 0;

						.cover {
							width: 240px;
							height: 240px;
							display: flex;
							justify-content: center;
							align-items: center;

							img {
								max-width: 100%;
								max-height: 100%;
							}
						}

						.name {
							text-overflow: -o-ellipsis-lastline;
							overflow: hidden;
							text-overflow: ellipsis;
							display: -webkit-box;
							-webkit-line-clamp: 1;
							line-clamp: 1;
							-webkit-box-orient: vertical;
							margin-top: 15px;
							text-align: center;
						}
					}
				}
			}
		}
	}
}

::v-deep {
	.select-box .select-wrap input.illegal {
		border: 1px solid #3385FF;
		color: #3385FF;
	}

	.select-box .select-wrap input.illegal [data-v-77a3eesf]:focus {
		border-color: #3385FF;
		color: #3385FF;
	}

	#goods-detail-container .goods-detail-container .goods-detail .price-box .baojia {
		border: 1px solid #D9262C;
		color: #D9262C;
	}

	#goods-detail-container .goods-detail-container .goods-detail .actions .btn.btn-collect.collected {
		background: red;
	}


}

// ::v-deep {
//   .goods-detail .filter-item .select-box .el-input__inner::-webkit-input-placeholder {
//     color: #f1d6d6;
//   }

//   .goods-detail .filter-item .select-box .el-input__inner {
//     // border-color: #d9262c;
//     color: #d9262c;
//   }

//   .goods-detail .filter-item .select-box .el-input__inner:focus {
//     border-color: #d9262c;
//   }

//   .opt-tab-box .opt-tab-item.active {
//     background-color: #d9262c;
//     border: #d9262c;
//   }

//   .right-section .goods-detail .filter-container .filter-item .data-box .select-box {
//     width: 300px;
//     border: #f1d6d6;

//   }

//   .select-box .select-wrap input.illegal {
//     // border: none;
//     color: #d9262c;
//   }

//   .select-box .select-wrap input[data-v-77a30e8f]::-webkit-input-placeholder {
//     color: #d9262c;
//     opacity: .5;
//     width: 100%;
//   }

//   .select-box .select-wrap input {
//     color: #d9262c;
//   }

//   .right-section .goods-detail .filter-container .filter-item .light-color {
//     color: #d9262c;
//   }

//   .right-section .goods-detail .filter-container .filter-item .data-box .copy-btn {
//     background-color: #d9262c;
//   }

//   .right-section .goods-detail .price-box .baojia {
//     color: #f1d6d6;
//   }

//   .select-box .select-wrap input:focus {
//     border-color: #d9262c;
//   }


// }
</style>
